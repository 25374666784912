@import '../abstracts/mixins';
@import '../abstracts/variables';

.showings-container {
  padding: 24px;
  @include media(mobile) {
    padding: 24px 0;
  }

  .page-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    @include media(mobile) {
      margin: 0 10px;
    }
  }

  .showings-content {
    display: flex;
    flex-direction: row;
    @include media(mobile) {
      flex-direction: column;
    }
    @include media(tablet) {
      flex-direction: column;
    }
  }
}
