@import '../abstracts/mixins';
@import '../abstracts/variables';

$sidebar-bg-color: #faf8fb;
$icon-bg-color: transparent;
$breakpoint-lg: 949px;
$highlight-color: #443461 !default;
$sidebar-width: 273px;
$sidebar-color: #737373;
.pro-sidebar-inner {
  @media (max-width: 992px) {
    border-radius: 0px 40px 40px 0px;
  }
}
@import '~react-pro-sidebar/dist/scss/styles.scss';

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'),
    url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'),
    url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'),
    url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local('Lato Bolder'), local('Lato-Bolder'),
    url(https://fonts.googleapis.com/css?family=Lato:900&display=swap)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Sharp Grotesk Book';
  font-style: normal;
  src: url('/fonts/SharpGrotesk/SharpGroteskBook20.ttf');
}
@font-face {
  font-family: 'Sharp Grotesk Medium';
  font-style: normal;
  src: url('/fonts/SharpGrotesk/SharpGroteskMedium20.ttf');
}
@font-face {
  font-family: 'Sharp Grotesk Semibold';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/SharpGrotesk/SharpGroteskSmBold22.ttf');
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

html {
  position: relative;
  min-height: 100%;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #463264!important;
}

#app {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#app-inner {
  min-width: 100%;
  min-height: 100%;
}

.app-container {
  height: 100%;
  width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  display: flex;
  flex-direction: column;
  flex: 1;
  .app-content-container {
    padding-left: 0px;
    padding-right: 0px;
    .content {
      display: flex;
      overflow: auto;
      .content-child {
        flex: 1;
      }
      .content-child > div {
        max-width: 100vw;
        overflow: auto !important;
      }
    }
    .content > div {
      overflow: auto;
    }
    .content-signup {
      flex: 1;
    }
    .content-signup > div {
      max-width: 100vw;
      height: auto !important;
      overflow: hidden !important;
    }
  }
}

.pro-sidebar {
  width: 250px;
  min-width: 250px;
}
.pro-sidebar.lg.toggled {
  ::-webkit-scrollbar {
    display: none;
  }
}
.pro-sidebar.collapsed
  .pro-menu.round
  .pro-menu-item.active
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #e3deed;
  border-radius: 10px;
}
.pro-sidebar .pro-menu li:first-child.pro-menu-item > .pro-inner-item:hover {
  @include media(tablet) {
    border-radius: 0px 25px 0 0px;
  }
  @include media(mobile) {
    border-radius: 0px 25px 0 0px;
  }
}
.page-container {
  max-width: 100vw;
  overflow: hidden;
  flex: 1;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.tool-container {
  flex: 1;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

ul,
ol {
  list-style: none;
}

h3 {
  font-size: 2em;
  line-height: 1em;
  margin: 5px 0;
  font-weight: 400;
}

a {
  color: $torii-plum;
  &:visited {
    color: $torii-plum;
  }
  &:hover {
    color: $torii-plum;
  }
}

table {
  border-collapse: collapse;
  td,
  th {
    border: 1px solid #333333;
    text-align: left;
    padding: 8px;
  }
}

.header {
  background-color: $purple-primary !important;
  .toolHeader {
    box-shadow: none;
    height: 70px;
    min-height: 56px;
    flex-wrap: nowrap;
    @include media(tablet) {
      padding: 0 20px;
    }
    @include media(mobile) {
      padding: 0 10px;
    }
    .dashboard-toggle-icon-button {
      height: 30px;
      min-width: 30px;
      border: radius 50%;
      padding: 0;
    }
  }
  .nav-links-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    .nav-dropdown {
      margin: 0px 0px 0px 16px;
      display: flex;
      align-items: center;
      .MuiSelect-select.MuiSelect-select {
        padding: 0px;
      }
      .buy-option-label {
        color: #ffffff;
        position: absolute;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        text-decoration: none;
        z-index: 10;
      }
    }
    .nav-links {
      color: #faf5f0;
      cursor: pointer;
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      margin: 0px 16px;
    }
  }
  .header-link {
    display: inline-block;
    text-decoration: none;
    text-transform: initial;
    cursor: pointer;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: $light-text-color;
    border-radius: 8px;
    padding: 10px;
    margin: 0px 10px;
    &:hover {
      color: $dark-text-color;
      background-color: $cream-background;
    }
    &.active {
      background-color: #f5f5f5;
    }
    &.signup-link {
      background-color: $pine-green;
      color: $light-text-color;
      padding: 8px 24px;
      &:hover {
        color: $pine-green;
        background-color: $light-text-color;
      }
    }
  }
  .avatar-container {
    border-radius: 20px;
    .avatar {
      margin-right: 10px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      vertical-align: middle;
      cursor: pointer;
    }
    .user-name {
      color: #ffffff;
      font-size: 18px;
      font-style: normal;
      font-weight: normal;
      margin-right: 10px;
    }
    .toggle-arrow {
      transition: transform 0.25s;
      &.up {
        transform: rotate(180deg);
      }
      &.down {
        transform: rotate(0deg);
      }
    }
  }

  .menu-dropdown {
    .avatar {
      margin-right: 10px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      vertical-align: middle;
      cursor: pointer;
    }
    .user-name {
      color: #ffffff;
      font-size: 18px;
      font-style: normal;
      font-weight: normal;
      margin-right: 10px;
    }
  }

  .logo {
    height: 31px;
    width: 123px;
    background: url('/static/img/torii_logo/Torii-Logo-Inverted.svg') no-repeat
      0 0;
    cursor: pointer;
    @media only screen and (max-width: 1350px) and (min-width: 1160px) {
      width: 100px;
      height: 27px;
    }
    @media only screen and (max-width: 1160px) and (min-width: 949px) {
      width: 85px;
      height: 25px;
    }
    @include media(mobile) {
      background: url('/static/img/torii_logo/Torii-House-Logo-Only.svg')
        no-repeat 0 0;
      height: 33px;
      width: 30px;
    }
  }
  .header-search {
    margin-left: 25px;
    min-height: 40px;
    padding: 0px 10px;
    background-color: #ffffff;
    border: 1px solid #443461;
    border-radius: 10px;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 1350px) and (min-width: 1160px) {
      margin-left: 20px;
    }
    @media only screen and (max-width: 1160px) and (min-width: 949px) {
      margin-left: 10px;
    }
    @include media(mobile) {
      margin-left: 20px;
      padding: 0px;
      border: none;
    }
    .header-search-container {
      @include media(tablet) {
        width: 100%;
      }
      @include media(mobile) {
        margin: 0 10px;
        width: 100%;
      }
    }
    .header-search-icon {
      margin-right: 5px;
      display: flex;
      @include media(mobile) {
        order: -1;
      }
    }
    .header-search-input {
      width: 450px;
      @include media(tablet) {
        width: 400px;
      }
      @include media(mobile) {
        width: 200px;
      }
      .MuiInputBase-input {
        @media only screen and (max-width: 1350px) and (min-width: 1160px) {
          font-size: 16px !important;
        }
        @media only screen and (max-width: 1160px) and (min-width: 949px) {
          padding: 5px;
          font-size: 14px !important;
        }
      }
    }
  }
  .header-menu {
    display: inline-block;
    padding-left: 10px !important;
  }
}

.nav-links-select {
  .MuiMenu-paper {
    margin-top: 10px;
    border-radius: 16px;
    .MuiMenu-list {
      padding: 0px;
    }
  }
}

.nav-dropdown-link {
  margin: 16px;
  display: flex;
  align-items: center;
  span {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
  }
}

.header-menu-section-label {
  color: #323232;
  font-size: 10px;
  margin: 8px 0 5px 10px;
  &.general-label {
    margin: 0 0 5px 10px;
  }
}

.header-menu-divider {
  margin: 10px 0px;
  border: 1px solid #e2e2e2;
}

.section-divider {
  width: 500px;
  height: 2px;
  background-image: linear-gradient(to right, $grey-accent 50%, white 10%);
  background-position: top;
  background-size: 15px 2px;
  background-repeat: repeat-x;
  @include media(mobile) {
    width: 90%;
  }
}

a.menu-link {
  text-decoration: none;
  &:hover {
    color: $purple-primary;
    text-decoration: underline;
  }
}

.list-inline {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.list-inline > li {
  display: inline-block;
  padding: 0px;
}

.showings-calendar {
  .MuiFilledInput-input {
    padding: 10px 12px;
  }
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: 0;
  }
  .MuiFilledInput-root {
    border-radius: 4px;
  }
}

footer {
  width: 100%;
  padding: 34px 0;
  background: #463264;
  color: white;
  @include media(mobile) {
    padding: 0;
  }
  .footer-inner {
    max-width: 1400px;
    padding: 0 34px;
    @include media(mobile) {
      padding: 1.5rem;
      padding: 60px 24px;
    }
  }
  .link-container {
    background-color: transparent;
    border: none;
    display: block;
    margin: 0px;
    outline: none;
    padding: 0px;
    text-align: left;
  }
  .social-container {
    margin-bottom: 20px;
    .social-icon-button {
      display: inline-block;
      margin-right: 15px;
      .social-icon-button-2 {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0px;
        margin: 0px;
      }
      .social-icon {
        height: 32px;
        width: 32px;
        text-indent: -9999999px;
        &.fb {
          background: url('/static/img/social_icon_fb.png') no-repeat 0 0;
          background-size: 100%;
        }
        &.tw {
          background: url('/static/img/social_icon_tw.png') no-repeat 0 0;
          width: 31px;
          background-size: 100%;
        }
        &.li {
          background: url('/static/img/social_icon_li.png') no-repeat 0 0;
          background-size: 100%;
        }
        &.ig {
          background: url('/static/img/social_icon_ig.png') no-repeat 0 0;
          background-size: 100%;
        }
      }
    }
  }
  .footer-column {
    @include media(mobile) {
      margin-bottom: 12px;
    }
    @include media(tablet) {
      margin-bottom: 12px;
    }
    &.no-mobile-margin {
      @include media(mobile) {
        margin-bottom: 0;
      }
    }
  }
  p {
    font-weight: 300;
    line-height: 22px;
  }
  .column-header {
    font-weight: 700;
    line-height: 16.8px;
    margin-bottom: 8px;
    color: rgba(255, 255, 255, 1);
    @include media(mobile) {
      margin-bottom: 8px;
    }
  }
  .column-spacer {
    height: 25px;
    @include media(tablet-small) {
      height: 0;
    }
    @include media(mobile) {
      height: 0;
    }
  }
  .footer-link {
    display: block;
    text-decoration: none;
    color: white;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    cursor: pointer;
    &:visited {
      color: white;
    }
    &:hover {
      color: $purple-secondary;
    }
    &:active {
      color: $purple-secondary;
    }
    &.disabled {
      color: #cccccc;
      cursor: auto;
    }
  }
  .footer-bottom {
    margin-top: 10px;
    .footer-eho-container {
      height: 50px;
      width: 50px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 18px 0 0;
      background-image: url('/static/img/eho_logo.png');
    }
    .torii-logo {
      height: 50px;
      width: 50px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 18px 0 0;
      background-image: url('/static/img/torii_logo/Torii-House-Logo-Only.svg');
    }
    .pride-flag-container {
      height: 50px;
      width: 65px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 18px 0 0;
      background-image: url('/static/img/pride_flag.svg');
    }
    .realtor-logo {
      height: 50px;
      width: 43px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 18px 0 0;
      background-image: url('/static/img/realtor_logo.png');
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 19.6px;
      @include media(mobile) {
        margin-top: 10px;
      }
    }
  }
}

label {
  display: block;
  line-height: 1.8;
}

input[type='radio'] + label,
input[type='checkbox'] + label {
  display: inline-block;
  margin: 0 5px;
}

// The two rules below are to avoid the yellow collor on Chrome autocompleted inputs
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.input-left {
  padding-left: 0;
  @include media(tablet) {
    padding-right: 0;
  }
  @include media(mobile) {
    padding-right: 0;
  }
}

.input-right {
  padding-right: 0;
  @include media(tablet) {
    padding-left: 0;
  }
  @include media(mobile) {
    padding-left: 0;
  }
}

.no-padding {
  padding: 0;
}

form button[type='submit'] {
  margin-top: 1em;
}

form .inline-input {
  margin-right: 10px;
  @include media(mobile) {
    margin-right: 0;
  }
}

.container {
  margin-bottom: 44px;
}

.form-container {
  margin-top: 30px;
  max-width: 270px;
  padding: 20px 30px 40px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.alert {
  margin: 8px 0;
  @include media(mobile) {
    margin: 8px auto;
    text-align: center;
  }
}

.text-success {
  color: #43a047;
}

.text-danger {
  color: #f44336;
}

.text-info {
  color: #ff9800;
}

.auth-button {
  text-indent: -99999px;
  border: none;
  cursor: pointer;
}

.facebook-auth {
  width: 250px;
  height: 40px;
  background: url('/static/img/fb_login_button.png') no-repeat 0 0;
  background-size: 100%;
}

.google-auth {
  width: 250px;
  height: 41px;
  background: url('/static/img/google_login_button.png') no-repeat 0 0;
  background-size: 100%;
}

.legal-container {
  padding-bottom: 20px;
  h4 {
    text-transform: uppercase;
  }
  ul {
    li {
      margin: 15px 0;
    }
  }
}

.progress-container {
  text-align: center;
  overflow: visible;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.listing-top-item {
  text-align: left;
  margin: 40px 0;
  .listing-price-title {
    align-items: center;
    display: flex;
    @include media(mobile) {
      flex-direction: column;
    }
    .details-box {
      align-items: center;
      border-radius: 3px;
      display: flex;
      height: 25px;
      margin: 0 0 0 15px;
      padding: 0 10px;
    }
    .listing-top-label {
      @include media(mobile) {
        width: 100%;
      }
    }
    .savings {
      background-color: $pine-green;
      margin: 0 15px;
      @include media(mobile) {
        margin-bottom: 5px;
      }
    }
    .details-text {
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      margin: 0;
    }
  }
  @include media(mobile) {
    text-align: center;
    margin: 10px 0;
  }
  .listing-top-label {
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    color: $text-color-light;
    margin: 0;
  }
}

.listing-pipe {
  padding: 0 3px;
}

.disclaimer {
  color: #999;
  font-size: 10px;
  max-width: 1050px;
  margin: 20px auto 0;
  text-align: left;
  @include media(mobile) {
    width: 90%;
    margin: 0 auto;
  }
}

.signup-container {
  max-width: 400px;
}

.left-input {
  max-width: 48%;
  @include media(tablet-small) {
    max-width: 100%;
  }
  @include media(mobile) {
    max-width: 100%;
  }
}

.no-padding-left {
  padding-left: 0 !important;
}

.full-width {
  width: 100% !important;
}

.content-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  &.top-xs {
    .content-overlay-cta {
      margin-top: 100px;
    }
  }
  .content-overlay-blur {
    background-image: -webkit-linear-gradient(
      bottom left,
      rgba(43, 40, 50, 0.95) 0%,
      rgba(83, 86, 99, 0.95) 45%,
      rgba(69, 77, 92, 0.95) 60%
    );
    background-image: -moz-linear-gradient(
      bottom left,
      rgba(43, 40, 50, 0.95) 0%,
      rgba(83, 86, 99, 0.95) 45%,
      rgba(69, 77, 92, 0.95) 60%
    );
    background-image: -o-linear-gradient(
      bottom left,
      rgba(43, 40, 50, 0.95) 0%,
      rgba(83, 86, 99, 0.95) 45%,
      rgba(69, 77, 92, 0.95) 60%
    );
    background-image: linear-gradient(
      to top right,
      rgba(43, 40, 50, 0.95) 0%,
      rgba(83, 86, 99, 0.95) 45%,
      rgba(69, 77, 92, 0.95) 60%
    );
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(30px);
    -o-filter: blur(30px);
    filter: blur(30px);
    right: 0;
    left: 0;
    z-index: 1;
  }
  .content-overlay-cta {
    height: 140px;
    width: 240px;
    z-index: 2;
    position: relative;
    h2.override {
      color: white;
      font-size: 30px;
      font-weight: bold;
      line-height: 36px;
      margin-bottom: 15px;
    }
  }
}

.gradient-footer {
  background: url('/static/img/gradient_footer_desktop.png') no-repeat 0;
  background-size: 100% 100%;
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-top: 100px;
  @include media(tablet) {
    background: url('/static/img/gradient_footer_tablet.png') no-repeat 0;
    background-size: 100% 100%;
    height: 125px;
  }
  @include media(mobile) {
    background: url('/static/img/gradient_footer_mobile.png') no-repeat 0;
    background-size: 100% 100%;
    height: 106px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.a1 {
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
}

.a2 {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.a3 {
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
}

.a4 {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
}

.a5 {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

.a6 {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
}

.d1 {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.d2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.d3 {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}

.d4 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.d5 {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.d6 {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
