@import '/styles/sass/abstracts/mixins';
@import '../abstracts/variables';

.sidebarContainer {
  .menuItems {
    padding: 30px 0px 30px 0px;
    .menuItem {
      font-size: 16px;
      line-height: 24px;
      margin: 20px 0px;
      :hover {
        background: #e3deed;
      }
      :hover span {
        color: #443461;
      }
    }
    .subMenuItem {
      padding-left: 25px;
    }

    .collapsedSelectedMenuItem {
      span {
        color: #443461;
      }
      background: transparent;
    }

    .selectedMenuItem {
      span {
        color: #443461;
      }
      background: #e3deed;
    }

    .simpleLine {
      border: 0;
      height: 1px;
      background: #e2e2e2;
      width: 100%;
    }
  }
  .hamburger {
    display: none;
  }

  @media (max-width: 992px) {
    .hamburger {
      display: flex;
      position: absolute;
      right: 2%;
      top: 2%;
      cursor: pointer;
      z-index: 101;
    }
  }

  .signupButton {
    background: $purple-primary;
    border-radius: 50px;
    height: 56px;
    width: 180px;
    span {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  .userDetails {
    display: flex;
    flex-direction: column;
    .profileContainer {
      margin: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .userImage {
        height: 108px;
        width: 108px;
        border-radius: 50%;
        background-image: url('./../../../public/static/img/torii_logo/Torii-Logo-Color.png');
      }
      .userName {
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 24px;
        color: #443461;
        margin-top: 16px;
      }
    }
  }
}
