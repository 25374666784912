//Tools
.tool-background-image {
  position: absolute;
}

.header-container {
  .tool-dot {
    &.best-buys {
      background: #ef7a59;
      background: radial-gradient(circle, #ee8245 0%, #fe975f 100%);
    }
    &.closing-costs {
      background: #463264;
      background: radial-gradient(circle, #81c7ff 0%, #42a5f5 100%);
    }
    &.comps-chomper {
      background: #ffa0be;
      background: radial-gradient(circle, #de90ee 0%, #ca79db 100%);
    }
    &.fixer-finder {
      background: #ffd750;
      background: radial-gradient(circle, #fc8caf 0%, #e2658c 100%);
    }
    &.fresh-finds {
      background: #87c864;
      background: radial-gradient(circle, #6be17e 0%, #57d16b 100%);
    }
    &.monthly-market {
      background: #5ad7ff;
      background: radial-gradient(circle, #24314f 0%, #425277 100%);
    }
    &.price-pointer {
      background: #ffd750;
      background: radial-gradient(circle, #f1b422 0%, #ffbb34 100%);
    }
    &.rent-cruncher {
      background: #463264;
      background: radial-gradient(circle, #6e4ca4 0%, #765aa6 100%);
    }
    &.trend-spotter {
      background: #195a50;
      background: radial-gradient(circle, #50d9ba 0%, #195a50 100%);
    }
    &.tool-box {
      background: #ffff;
      background: radial-gradient(
        circle,
        rgb(255, 255, 255) 0%,
        rgb(255, 255, 255) 100%
      );
    }
  }
  .tool-box {
    color: #ffffff;
  }
}
.header-container + .tool-box {
  color: #ffffff !important;
}

.comps-loader {
  display: flex;
  justify-content: center;
  margin: 50px;
  left: 0;
  right: 0;
}

.address-card-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  h3 {
    text-align: center;
  }
}

.price-estimate-text {
  font-size: 36px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bold;
}

.averages-text {
  margin-bottom: 10px;
  font-size: 14px;
  color: #323232;
}

.footer-botton-container {
  margin-right: 20px;
  margin-bottom: 0px;
  justify-content: center;
  @include media(mobile) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.lock-range-sub-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.background-image {
  position: absolute;
  top: 100;

  z-index: 0;
}

//Sign Up Page
.signup-page {
  .reviews-header-container {
    .testimonials {
      .slider {
        @include media(mobile) {
          height: 295px !important;
        }
      }
      @include media(mobile) {
        .slider-frame {
          width: 360px;
          .slider-slide {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      ul {
        .testimonial-item {
          margin: 0 auto;
          max-width: 250px;
          @include media(tablet) {
            margin: 15px auto 0;
          }
          @include media(mobile) {
            width: 100%;
            margin: 15px auto;
          }
          .quote-avatar {
            height: 100px;
            width: 100px;
            border-radius: 50px;
            margin-bottom: 15px;
          }
          .quote {
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
          }
          .info {
            font-size: 14px;
            color: $purple-primary;
            font-weight: bold;
            margin-top: 15px;
            @include media(mobile) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  .google-auth {
    margin-top: 10px;
  }
}

//New Sign-up Page
.stage-end-form-container {
  width: 520px;
  @include media(tablet) {
    width: 100%;
  }
  @include media(mobile) {
    width: 100%;
  }
  .sign-up-input-container {
    width: 100%;
    .input-label {
      margin-bottom: 8px;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: justified;
    }
    .sign-up-input {
      margin-bottom: 24px;
      .MuiInputBase-root {
        min-height: 48px;
        border-radius: 10px;
        background-color: #ffffff;
        .MuiInputBase-input {
          padding: 16px;
          color: #323232;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0px;
          text-align: left;
        }
      }
    }
  }
  .sign-up-nav-button-container {
    margin-top: 50px;
    @include media(tablet) {
      margin: 0px auto;
      margin-top: 50px;
    }
    @include media(mobile) {
      margin: 0px auto;
      margin-top: 50px;
    }
    .signup-button {
      @include media(mobile) {
        width: 100%;
      }
    }
    .sign-up-button-text {
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
    }
    .auth-button-text {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-style: normal;
      line-height: 32px;
      letter-spacing: 0px;
      margin-left: 10px;
    }
  }
  .sign-up-checkbox-input-container {
    display: flex;
    align-items: center;
    @include media(tablet) {
      align-items: flex-start;
    }
    @include media(mobile) {
      align-items: flex-start;
    }
    .sign-up-checkbox-input {
      width: 18px;
      height: 18px;
      margin-right: 12.5px;
    }
    .sign-up-link-text {
      color: #323232;
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: justified;
    }
    .sign-up-input-link {
      color: #323232;
      font-family: 'Lato', sans-serif;
      margin-bottom: 40px;
    }
  }
}

//New Sign-up pages
.sign-up-progress-bar {
  background-color: #c4c4c4;
  width: 100%;
  height: 8px;
  .progress-bar {
    width: 50%;
    height: 100%;
    background-color: #2c5853;
  }
}
.sign-up-form-page {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 75em) and (min-width: 950px) {
    padding-top: 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  @include media(tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  @include media(mobile) {
    padding-top: 30px;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .image-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media only screen and (max-width: 75em) and (min-width: 950px) {
      width: 100%;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @include media(tablet) {
      width: 100%;
      align-items: center;
      margin-bottom: 40px;
    }
    @include media(mobile) {
      width: 100%;
      align-items: center;
      margin-bottom: 40px;
    }
    .image-body {
      position: relative;
      z-index: 1;
      .image {
        width: 500px;
        height: 320px;
        @media only screen and (max-width: 75em) and (min-width: 950px) {
          width: 325px;
          height: 240px;
        }
        @include media(tablet) {
          width: 325px;
          height: 240px;
        }
        @include media(mobile) {
          width: 215px;
          height: 159px;
        }
      }
      .activity-image {
        background-image: url('/static/img/home/sign-up/desktop/activity.png');
        background-size: cover;
        background-repeat: no-repeat;
        @include media(tablet) {
          background-image: url('/static/img/home/sign-up/tablet/activity.png');
        }
        @include media(mobile) {
          background-image: url('/static/img/home/sign-up/mobile/activity.png');
        }
      }
      .details-image {
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 30px;
        background: linear-gradient(141deg, #443461 10%, #c8bddb 75%);
        &.image-name {
          background-image: url('/static/img/sign-up/introduction/Name-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/introduction/Name-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/introduction/Name-Mobile.png');
          }
        }
        &.image-intent {
          background-image: url('/static/img/sign-up/introduction/Intent-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/introduction/Intent-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/introduction/Intent-Mobile.png');
          }
        }
        &.image-knowledge {
          background-image: url('/static/img/sign-up/introduction/Knowledge-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/introduction/Knowledge-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/introduction/Knowledge-Mobile.png');
          }
        }
        &.image-property {
          background-image: url('/static/img/sign-up/information/basics/Property-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/basics/Property-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/basics/Property-Mobile.png');
          }
        }
        &.image-location {
          background-image: url('/static/img/sign-up/information/basics/Location-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/basics/Location-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/basics/Location-Mobile.png');
          }
        }
        &.image-budget {
          background-image: url('/static/img/sign-up/information/basics/Budget-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/basics/Budget-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/basics/Budget-Mobile.png');
          }
        }
        &.image-bedbath {
          background-image: url('/static/img/sign-up/information/basics/BedBath-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/basics/BedBath-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/basics/BedBath-Mobile.png');
          }
        }
        &.image-squarefeet {
          background-image: url('/static/img/sign-up/information/basics/SquareFeet-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/basics/SquareFeet-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/basics/SquareFeet-Mobile.png');
          }
        }
        &.image-parking {
          background-image: url('/static/img/sign-up/information/basics/Parking-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/basics/Parking-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/basics/Parking-Mobile.png');
          }
        }
        &.image-features {
          background-image: url('/static/img/sign-up/information/basics/Features-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/basics/Features-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/basics/Features-Mobile.png');
          }
        }
        &.image-homeStyles {
          background-image: url('/static/img/sign-up/information/basics/HomeStyles-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/basics/HomeStyles-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/basics/HomeStyles-Mobile.png');
          }
        }
        &.image-timeframe {
          background-image: url('/static/img/sign-up/information/basics/Timeframe-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/basics/Timeframe-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/basics/Timeframe-Mobile.png');
          }
        }
        &.image-agent {
          background-image: url('/static/img/sign-up/information/basics/Agent-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/basics/Agent-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/basics/Agent-Mobile.png');
          }
        }
        &.image-preapproval {
          background-image: url('/static/img/sign-up/information/basics/PreApproval-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/basics/PreApproval-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/basics/PreApproval-Mobile.png');
          }
        }
        &.image-amenities {
          background-image: url('/static/img/sign-up/information/neighborhood/Amenities-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/neighborhood/Amenities-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/neighborhood/Amenities-Mobile.png');
          }
        }
        &.image-commute {
          background-image: url('/static/img/sign-up/information/neighborhood/Commute-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/neighborhood/Commute-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/neighborhood/Commute-Mobile.png');
          }
        }
        &.image-sound {
          background-image: url('/static/img/sign-up/information/neighborhood/Sound-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/neighborhood/Sound-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/neighborhood/Sound-Mobile.png');
          }
        }
        &.image-describe {
          background-image: url('/static/img/sign-up/information/lifestyle/DescribeYourself-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/lifestyle/DescribeYourself-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/lifestyle/DescribeYourself-Mobile.png');
          }
        }
        &.image-important-room {
          background-image: url('/static/img/sign-up/information/lifestyle/ImportantRoom-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/lifestyle/ImportantRoom-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/lifestyle/ImportantRoom-Mobile.png');
          }
        }
        &.image-pets {
          background-image: url('/static/img/sign-up/information/lifestyle/Pets-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/lifestyle/Pets-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/lifestyle/Pets-Mobile.png');
          }
        }
        &.image-home-activity {
          background-image: url('/static/img/sign-up/information/lifestyle/HomeActivities-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/lifestyle/HomeActivities-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/lifestyle/HomeActivities-Mobile.png');
          }
        }
        &.image-outside-activity {
          background-image: url('/static/img/sign-up/information/lifestyle/OutsideActivities-Desktop.png');
          @include media(tablet) {
            background-image: url('/static/img/sign-up/information/lifestyle/OutsideActivities-Tablet.png');
          }
          @include media(mobile) {
            background-image: url('/static/img/sign-up/information/lifestyle/OutsideActivities-Mobile.png');
          }
        }
      }
      .email-image {
        background-image: url('/static/img/home/sign-up/desktop/email.png');
        background-size: cover;
        background-repeat: no-repeat;
        @include media(tablet) {
          background-image: url('/static/img/home/sign-up/tablet/email.png');
        }
        @include media(mobile) {
          background-image: url('/static/img/home/sign-up/mobile/email.png');
        }
      }
      .phone-image {
        background-image: url('/static/img/home/sign-up/desktop/phone.png');
        background-size: cover;
        background-repeat: no-repeat;
        @include media(tablet) {
          background-image: url('/static/img/home/sign-up/tablet/phone.png');
        }
        @include media(mobile) {
          background-image: url('/static/img/home/sign-up/mobile/phone.png');
        }
      }
      .state-image {
        background-image: url('/static/img/home/sign-up/desktop/location.png');
        background-size: cover;
        background-repeat: no-repeat;
        @include media(tablet) {
          background-image: url('/static/img/home/sign-up/tablet/location.png');
        }
        @include media(mobile) {
          background-image: url('/static/img/home/sign-up/mobile/location.png');
        }
      }
      .thankyou-image {
        background-image: url('/static/img/home/sign-up/desktop/final.png');
        background-size: cover;
        background-repeat: no-repeat;
        @include media(tablet) {
          background-image: url('/static/img/home/sign-up/tablet/final.png');
        }
        @include media(mobile) {
          background-image: url('/static/img/home/sign-up/mobile/final.png');
        }
      }
      .plum-vector {
        position: absolute;
        top: -15%;
        left: 12.5%;
        z-index: 3;
      }
      .plum-vector-mini {
        position: absolute;
        bottom: -17.5%;
        left: -10%;
        z-index: 3;
      }

      .clementine-vector {
        position: absolute;
        z-index: 3;
        top: 10%;
        right: -7.5%;
      }
      .clementine-vector-mini {
        position: absolute;
        top: -12.5%;
        right: -15%;
        z-index: -1;
      }

      .carnation-vector {
        position: absolute;
        z-index: 3;
        bottom: -17.5%;
        right: 20%;
      }
      .sunshine {
        position: absolute;
        z-index: 3;
        top: 20%;
        right: -10%;
      }
    }
  }
  .sign-up-form-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 25px;
    @media only screen and (max-width: 75em) and (min-width: 950px) {
      width: 100%;
      padding: 0px 125px;
    }
    @include media(tablet) {
      width: 100%;
      padding: 0px 125px;
    }
    @include media(mobile) {
      width: 100%;
      padding: 0px 16px;
    }
    .check-circle {
      margin-bottom: 32px;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        margin: 0px auto;
      }
      @include media(tablet) {
        margin: 0px auto;
      }
      @include media(mobile) {
        margin: 0px auto;
      }
    }
    .button-container {
      width: 100%;
      @include media(tablet) {
        margin: 0px auto 20px;
      }
      @include media(mobile) {
        margin: 0px auto;
      }

      .button-box {
        display: flex;
        align-items: center;
        @include media(mobile) {
          flex-direction: column;
          flex-wrap: wrap;
        }
      }
      .house-style-button {
        background: #ffffff;
        border-radius: 10px;
        padding: 8px 8px 16px 8px;
        width: 193px;
        margin: 0 14px 14px 0;
        color: #2d2332;
        font-size: 18px;
        font-weight: bold;
        text-transform: none;
        border: 3px solid #ffffff;

        &.selected {
          border: 3px solid #463264;
        }
        .button-content {
          align-items: center;
          display: flex;
          flex-direction: column;
          .image-box {
            background-size: cover;
            background-repeat: no-repeat;
            width: 177px;
            height: 177px;
            border-radius: 10px;
            margin-bottom: 16px;
            &.bungalow {
              background-image: url('/static/img/sign-up/information/basics/homeStylesOptions/Bungalow_home.jpg');
            }
            &.colonial {
              background-image: url('/static/img/sign-up/information/basics/homeStylesOptions/Colonial_home.jpg');
            }
            &.contemporary {
              background-image: url('/static/img/sign-up/information/basics/homeStylesOptions/Contemporary_home.jpeg');
            }
            &.farmhouse {
              background-image: url('/static/img/sign-up/information/basics/homeStylesOptions/Farmhouse_home.jpg');
            }
            &.midCentury {
              background-image: url('/static/img/sign-up/information/basics/homeStylesOptions/Midcentury_home.jpg');
            }
            &.modern {
              background-image: url('/static/img/sign-up/information/basics/homeStylesOptions/Modern_home.jpg');
            }
            &.traditional {
              background-image: url('/static/img/sign-up/information/basics/homeStylesOptions/Traditional_home.jpg');
            }
            &.tudor {
              background-image: url('/static/img/sign-up/information/basics/homeStylesOptions/Tudor_home.jpg');
            }
            &.victorian {
              background-image: url('/static/img/sign-up/information/basics/homeStylesOptions/Victorian_home.jpg');
            }
          }
        }
      }
      .dont-like {
        color: #463264;
        font-weight: 600;
        font-size: 24px;
        text-decoration-line: underline;
        cursor: pointer;
      }
      .multi-select-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        @media only screen and (max-width: 75em) and (min-width: 950px) {
          justify-content: center;
        }
        @include media(tablet) {
          justify-content: center;
        }
        @include media(mobile) {
          justify-content: center;
        }
      }
      .multi-select-button {
        height: 45px;
        margin: 0 16px 16px 0;
        padding: 8px 16px;
        border-radius: 60px;
        background: #ffffff;
        border: 1px solid #463264;
        color: #463264;
        font-size: 18px;
        text-transform: none;
        @include media(mobile) {
          padding: 8px;
          margin: 0 8px 8px 0;
        }
      }
      .multi-select-button-selected {
        height: 45px;
        margin: 0 16px 16px 0;
        padding: 8px 16px;
        border-radius: 60px;
        background: #463264;
        border: 1px solid #463264;
        color: #ffffff;
        font-size: 18px;
        text-transform: none;
        @include media(mobile) {
          padding: 8px;
          margin: 0 8px 8px 0;
        }
      }

      .square-button {
        margin: 0px;
        height: 108px;
        width: 200px;
        margin-right: 24px;
        margin-bottom: 24px;
        border: 2px solid #463264;
        border-radius: 20px;
        @include media(mobile) {
          margin-right: 0;
        }
        &.tall {
          height: 168px;
          width: 168px;
          padding: 15px;
        }
      }
      .button-square-box {
        background-color: #ffffff;
        .button-content {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: #463264;
          font-family: 'Poppins', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          text-transform: none;
          @include media(tablet) {
            font-size: 16px;
          }
          @include media(mobile) {
            font-size: 16px;
          }
        }
      }
      .button-square-box-checked {
        background-color: #463264;
        .button-content {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: #ffffff;
          font-family: 'Poppins', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          text-transform: none;
          @include media(tablet) {
            font-size: 16px;
          }
          @include media(mobile) {
            font-size: 16px;
          }
        }
      }
    }
    .radio-container {
      width: 100%;
      @include media(tablet) {
        margin: 0px auto;
      }
      @include media(mobile) {
        margin: 0px auto;
      }
      .radio-box-checked {
        margin: 0px;
        height: 64px;
        margin-bottom: 24px;
        border: 3px solid #463264;
        background-color: #ffffff;
        border-radius: 15px;
        .MuiTypography-body1 {
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: 0px;
          text-align: left;
          @include media(tablet) {
            font-size: 16px;
          }
          @include media(mobile) {
            font-size: 16px;
          }
        }
      }
      .radio-box {
        margin: 0px;
        height: 64px;
        margin-bottom: 24px;
        background-color: #ffffff;
        border: 2px solid #443461;
        border-radius: 15px;
        .MuiTypography-body1 {
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: 0px;
          text-align: left;
          @include media(tablet) {
            font-size: 16px;
          }
          @include media(mobile) {
            font-size: 16px;
          }
        }
      }
    }
    .text-container {
      display: flex;
      flex-direction: column;
      margin: 0px 0px 40px 0px;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        margin: 0px auto 40px auto;
        text-align: center;
      }
      @include media(tablet) {
        margin: 0px auto 40px auto;
        text-align: center;
      }
      @include media(mobile) {
        margin: 0px auto 24px auto;
        text-align: center;
      }
      .category-title {
        color: #195a50;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 8px;
        @media only screen and (max-width: 75em) and (min-width: 950px) {
          text-align: center;
        }
        @include media(tablet) {
          text-align: center;
        }
        @include media(mobile) {
          text-align: center;
        }
      }
      .title {
        color: rgba(0, 0, 0, 1);
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
        @media only screen and (max-width: 75em) and (min-width: 950px) {
          text-align: center;
        }
        @include media(tablet) {
          text-align: center;
        }
        @include media(mobile) {
          text-align: center;
        }
      }
      .sub-text {
        color: #636363;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        @media only screen and (max-width: 75em) and (min-width: 950px) {
          text-align: center;
        }
        @include media(tablet) {
          text-align: center;
        }
        @include media(mobile) {
          text-align: center;
        }
      }
      .sub-title {
        color: #555555;
        display: block;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0px;
        text-align: left;
        @media only screen and (max-width: 75em) and (min-width: 950px) {
          text-align: center;
        }
        @include media(tablet) {
          text-align: center;
        }
        @include media(mobile) {
          text-align: center;
        }
      }
      .emoji {
        margin-top: 32px;
        @include media(tablet) {
          margin-top: 48px;
        }
      }
      .greeting-text {
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 56px;
        letter-spacing: 0px;
        text-align: left;
        @media only screen and (max-width: 75em) and (min-width: 950px) {
          text-align: center;
        }
        @include media(tablet) {
          font-size: 24px;
          line-height: 48px;
          text-align: center;
        }
        @include media(mobile) {
          font-size: 24px;
          line-height: 48px;
          text-align: center;
        }
      }
    }
    .sign-up-input-container {
      width: 80%;
      position: relative;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        width: 100%;
      }
      @include media(tablet) {
        width: 100%;
      }
      @include media(mobile) {
        width: 100%;
      }
      .sign-up-state-select {
        width: 100%;
        position: relative;
        background-color: #ffffff;
        .state-label {
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translate(0%, -50%);
          font-family: 'Poppins', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0px;
          text-align: left;
        }
      }
      .sign-up-input {
        margin-bottom: 12px;
        min-height: 90px;
        .MuiInputBase-root {
          min-height: 64px;
          background-color: #ffffff;
          border-radius: 20px;
          .MuiInputBase-input {
            padding: 16px;
            color: #323232;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
          }
          .MuiInput-underline {
            border: 0;
          }
        }
        .MuiFormHelperText-root {
          padding: 0px 16px;
        }
      }
      .drop-down-input {
        min-width: 300px;
        background-color: #ffffff;
        border-radius: 20px;
        border: 0;
        height: 80px;
        width: 100%;
        color: #323232;
        font-size: 24px;
        z-index: 0;
      }
      .MuiSelect-outlined {
        border-radius: 20px;
        color: #323232;
        font-size: 24px;
      }
      .MuiSelect-outlined:focus {
        background-color: transparent;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }

    .dont-like {
      font-family: 'Sharp Grotesk Semibold', sans-serif;
      color: #463264;
      font-weight: 600;
      font-size: 24px;
      text-decoration-line: underline;
      cursor: pointer;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        margin: 16px auto;
      }
      @include media(tablet) {
        margin: 16px auto;
      }
      @include media(mobile) {
        margin: 16px auto;
      }
    }
    .warning-container {
      display: -webkit-inline-box;
      background-color: #ffd750;
      border-radius: 20px;
      padding: 24px 64px 24px 24px;
      width: 100%;
      margin-bottom: 15px;
      p {
        color: #826441;
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        margin: 0;
        margin-left: 19px;
      }
    }
    .info-container {
      display: -webkit-inline-box;
      border-radius: 20px;
      border: 1px solid #443461;
      padding: 24px 64px 24px 24px;
      width: 100%;
      margin-bottom: 15px;
      p {
        color: #463264;
        font-size: 14px;
        line-height: 32px;
        margin: 0;
        margin-left: 19px;
      }
    }

    .item-title {
      color: #323232;
      font-family: 'Sharp Grotesk Semibold', sans-serif;
      font-size: 24px;
      font-weight: 600;
      margin: 24px 0px;
      text-align: center;
    }

    .room-value-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        justify-content: center;
      }
      @include media(tablet) {
        justify-content: center;
      }
      @include media(mobile) {
        justify-content: center;
      }
      .value-box {
        background-color: #ffffff;
        border-radius: 20px;
        height: 64px;
        min-width: 74px;
        margin: 0 22px;
        padding: 16px;
        font-weight: 500;
        font-size: 24px;
        color: #463264;
        text-align: center;
      }
    }

    .sign-up-radio-container {
      width: 90%;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      @include media(tablet) {
        width: 100%;
      }
      @include media(mobile) {
        width: 100%;
      }
    }
    .sign-up-radio-room-container {
      width: 90%;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        width: 100%;
      }
      @include media(tablet) {
        width: 100%;
      }
      @include media(mobile) {
        width: 100%;
      }
      .button-box {
        @media only screen and (max-width: 75em) and (min-width: 950px) {
          justify-content: center;
        }
        @include media(tablet) {
          justify-content: center;
        }
        @include media(mobile) {
          justify-content: center;
        }
      }
    }
    .sign-up-button-container {
      width: 90%;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        width: 100%;
      }
      @include media(tablet) {
        width: 100%;
      }
      @include media(mobile) {
        width: 100%;
      }
    }
    .sign-up-nav-button-container {
      margin: 20px 0px;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        margin: 20px auto;
      }
      @include media(tablet) {
        margin: 20px auto;
      }
      @include media(mobile) {
        margin: 20px auto;
      }
      .sign-up-button-text {
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
        display: flex;
        align-items: center;
      }
    }
    .sign-up-nav-button-container-centered {
      width: 550px;
      text-align: center;
      @include media(tablet) {
        width: 100%;
      }
      @include media(mobile) {
        width: 100%;
      }
    }
    .input-label {
      color: rgba(85, 85, 85, 1);
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
    }
    .button-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        margin: 0px auto;
      }
      @include media(tablet) {
        margin: 0px auto;
      }
      @include media(mobile) {
        margin: 0px auto;
      }
    }
    .button {
      width: 'auto';
      min-width: 300px;
      padding: 16px 30px;
      height: 56px;
      color: #ffffff;
      background-color: $pine-green;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 2.25px;
      text-align: center;
      margin-top: 40px;
      border-radius: 10px;
      &:hover {
        background-color: $green-secondary;
      }
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        margin: 40px auto 0px auto;
      }
      @include media(tablet) {
        margin: 40px auto 0px auto;
      }
      @include media(mobile) {
        margin: 24px auto 0px auto;
      }
    }
    .button-disabled {
      width: 300px;
      height: 56px;
      background-color: transparent;
      color: rgba(151, 151, 151, 1);
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 2.25px;
      text-align: center;
      border: 2px solid rgba(151, 151, 151, 1);
      border-radius: 10px;
      margin-top: 40px;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        margin: 40px auto 0px auto;
      }
      @include media(tablet) {
        margin: 40px auto 0px auto;
      }
      @include media(mobile) {
        margin: 24px auto 0px auto;
      }
    }
    .button-outline {
      width: 300px;
      height: 56px;
      margin-top: 16px;
      background-color: #ffffff;
      color: $purple-primary;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 2.25px;
      text-align: center;
      border: 3px solid $purple-primary;
      border-radius: 10px;
      @media only screen and (max-width: 75em) and (min-width: 950px) {
        margin: 16px auto 0px auto;
      }
      @include media(tablet) {
        margin: 16px auto 0px auto;
      }
      @include media(mobile) {
        margin: 16px auto 0px auto;
      }
    }
  }
  .bedroom-bathroom-container {
    display: flex;
    flex-direction: row;
    @include media(tablet) {
      flex-direction: column;
    }
    @include media(mobile) {
      flex-direction: column;
    }
    .item-container {
      margin-right: 120px;
      @include media(tablet) {
        margin: 0px;
      }
      @include media(mobile) {
        margin: 0px;
      }
    }
  }
}

.sign-up-background {
  position: absolute;
  top: 11.5%;
  max-height: 100%;
  overflow: hidden;
  @media only screen and (max-width: 75em) and (min-width: 950px) {
    display: none;
  }
  @include media(tablet) {
    display: none;
  }
  @include media(mobile) {
    display: none;
  }
}

//Sign-up Dialogs
.save-dialog {
  .save-dialog-closeButton {
    position: absolute;
    top: 20px;
    right: 5px;
    z-index: 5;
  }
  .save-dialog-inner {
    width: 100%;
    padding: 24px;
    background-color: #faf5f0;
    display: flex;
    flex-direction: column;
    ::-webkit-scrollbar {
      display: none;
    }
    div {
      padding: 0px !important;
    }

    .heading {
      margin-top: 24px;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0px;
      text-align: left;
    }
    .sign-up-dialog-form-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 16px;
      .input-container {
        text-align: left;
        .input-label {
          color: #323232;
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0px;
          margin-bottom: 8px;
          .input-text-Link {
            color: #463264;
          }
        }
        .input {
          margin-bottom: 12px;
          .MuiInputBase-root {
            min-height: 48px;
            background-color: #ffffff;
            border-radius: 10px;
            .MuiInputBase-input {
              padding: 16px;
              color: #323232;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 32px;
              letter-spacing: 0px;
              text-align: left;
            }
          }
          .MuiFormHelperText-root {
            padding: 0px 16px;
          }
        }
      }
      .terms-condition {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: justify;
        color: #323232;
        .text-link {
          color: #463264;
        }
      }
      .form-submit {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        .button-text {
          color: #ffffff;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: justify;
        }
        .auth-button-text {
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-style: normal;
          line-height: 32px;
          letter-spacing: 0px;
          margin-left: 10px;
        }
      }
    }
    .buttons-container {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .button-text {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-transform: capitalize;
      }
    }
    .signup-success {
      margin-top: 70px;
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: 550px;
      @include media(mobile) {
        min-height: 100%;
      }
      .title {
        font-family: Sharp Grotesk Semibold;
        font-size: 36px;
        text-align: center;
        color: #323232;
        @include media(mobile) {
          font-size: 24px;
        }
      }
    }
  }
}

.welcome-survey-dialog {
  .dialog-closeButton {
    position: absolute;
    padding: 0;
    top: 22px;
    right: 24px;
    z-index: 5;
  }
  .parent-inner {
    height: 100%;
  }
  .welcome-dialog-inner {
    width: 100%;
    padding: 20px;
    background-color: #faf5f0;
    display: flex;
    flex-direction: column;
    height: 710px;
    overflow-y: scroll;
    position: relative;
    @include media(mobile) {
      height: 100%;
    }
    ::-webkit-scrollbar {
      display: none;
    }

    .buy-inner {
      display: flex;
      flex-direction: column;
      padding: 0 4px;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .title-text {
      color: #323232;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      text-align: left;
      margin: 18px 0;
    }
    .radio-box-checked {
      margin: 0px;
      height: 64px;
      margin-bottom: 24px;
      border: 3px solid #463264;
      background-color: #ffffff;
      border-radius: 15px;
      justify-content: center;
      .MuiTypography-body1 {
        color: #463264;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        @include media(tablet) {
          font-size: 16px;
        }
        @include media(mobile) {
          font-size: 16px;
        }
      }
      .MuiRadio-colorPrimary.Mui-checked {
        position: absolute;
        left: 35px;
        @include media(mobile) {
          position: relative;
          left: auto;
        }
      }
    }
    .radio-box {
      margin: 0px;
      height: 64px;
      margin-bottom: 24px;
      background-color: #ffffff;
      border: 2px solid #443461;
      border-radius: 15px;
      justify-content: center;
      .MuiTypography-body1 {
        color: #463264;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        @include media(tablet) {
          font-size: 16px;
        }
        @include media(mobile) {
          font-size: 16px;
        }
      }
      .MuiRadio-root {
        position: absolute;
        left: 35px;
      }
    }
    .sign-up-button-text {
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
    }
    .secondary-title {
      color: #323232;
      font-family: Sharp Grotesk Semibold;
      font-size: 24px;
      line-height: 42px;
      margin-top: 75px;
      text-align: center;
      white-space: pre-line;
    }
    .seconday-option-button {
      width: 300px;
      height: 56px;
      background: #ffffff;
      border: 2px solid #463264;
      box-sizing: border-box;
      border-radius: 10px;
      order: 1;
      margin: 8px 0px;
      .MuiTouchRipple-root span {
        background-color: #463264 !important;
        opacity: 0.3;
      }
      .MuiButton-label {
        color: #463264;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        text-transform: none;
      }
    }
    .question-title {
      color: #323232;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      text-align: left;
      margin: 18px 0;
    }
    .sign-up-input-container {
      width: 100%;
      .input-label {
        margin-bottom: 8px;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: justified;
      }
      .sign-up-input {
        margin-bottom: 24px;
        .MuiInputBase-root {
          min-height: 48px;
          border-radius: 10px;
          background-color: #ffffff;
          .MuiInputBase-input {
            padding: 16px;
            color: #323232;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }
    }
    .welcome-location-input-container {
      width: 100%;
      position: relative;
      .sign-up-state-select {
        width: 100%;
        position: relative;
        background-color: #ffffff;
        border-radius: 20px;
        .state-label {
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translate(0%, -50%);
          font-family: 'Poppins', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0px;
          text-align: left;
        }
      }
      .sign-up-input {
        margin-bottom: 12px;
        min-height: 90px;
        .MuiInputBase-root {
          min-height: 64px;
          background-color: #ffffff;
          border-radius: 20px;
          .MuiInputBase-input {
            padding: 16px;
            color: #323232;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
          }
          .MuiInput-underline {
            border: 0;
          }
        }
        .MuiFormHelperText-root {
          padding: 0px 16px;
        }
      }
      .drop-down-input {
        min-width: 300px;
        background-color: #ffffff;
        border-radius: 20px;
        border: 0;
        height: 80px;
        width: 100%;
        color: #323232;
        font-size: 24px;
        z-index: 0;
      }
      .MuiSelect-outlined {
        border-radius: 20px;
        color: #323232;
        font-size: 24px;
        text-align: left;
      }
      .MuiSelect-outlined:focus {
        background-color: transparent;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
      .welcome-warning-container {
        margin-top: 20px;
        display: inline-flex;
        background-color: #ffd750;
        border-radius: 20px;
        padding: 10px;
        p {
          color: #826441;
          margin: 0;
          margin-left: 5px;
        }
      }
    }
    .question-pagination {
      margin-bottom: 0;
      text-align: center;
    }
    .loader-text {
      color: #2d2332;
      font-family: 'Sharp Grotesk Semibold', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 31px;
      letter-spacing: 0px;
      text-align: center;
    }
    .thanks-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
  }
}

.save-prompt {
  .save-dialog-closeButton {
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 5;
  }
  .save-prompt-inner {
    width: 100%;
    height: 100%;
    padding: 24px;
    background-color: #faf5f0;
    display: flex;
    flex-direction: column;
    .warning-icon {
      position: absolute;
      bottom: 5px;
      left: 80px;
      z-index: 1;
    }
    .heading-text {
      color: #323232;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: center;
      margin-top: 20px;
    }
    .subtitle {
      color: #636363;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: center;
      margin-bottom: 20px;
    }
    .buttons-container {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .button-text {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-transform: none;
      }
    }
  }
}
