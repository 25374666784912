@import '../abstracts/mixins';

//Input Select.
.input-select-style {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

//Your Team Dashboard Dialog.
.your-team-dialog {
  .MuiDialog-paper {
    width: 350px;
    max-height: 350px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .team-dialog-btn-container {
      margin-left: auto;
    }
  }
}

//Delete Account Dialog.
.delete-account-dialog {
  .MuiDialog-paper {
    min-height: 246px;
    padding: 25px;
    width: 674px;
    @include media(mobile) {
      width: 90%;
      height: auto;
    }
  }
}

//Delete Save Search Dialog.
.save-search-dialog {
  .save-search-close-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .MuiDialog-paper {
    min-height: 246px;
    padding: 24px;
    @include media(mobile) {
      margin: 15px;
    }
  }
}

//Report Dialog
.reason-select {
  .MuiSelect-select.MuiSelect-select {
    padding: 0px;
    padding-bottom: 3.5px;
    padding-left: 3.5px;
  }
}

//Dropdown Select
.drop-down-select-form-control {
  .drop-down-select-field {
    min-width: 300px;
    display: flex;
    @include media(mobile) {
      min-width: 100%;
    }
  }
  .MuiSelect-selectMenu {
    text-align: initial;
  }
}

//Location Form
.location-form {
  .MuiCardContent-root {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .cap-rate-input-container {
    display: flex;
    min-width: 100%;
    flex-direction: column;
    @include media(mobile) {
      margin-top: 20px;
      min-width: 100%;
    }
  }
}

//Listing Styles
@import '../../node_modules/react-grid-layout/css/styles.css';
@import '../../node_modules/react-confirm-alert/src/react-confirm-alert.css';
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.25);
}
.MuiTypography-body1 {
  font-family: Poppins !important;
  letter-spacing: 0 !important;
  line-height: 1.5 !important;
}
.listing-carousel {
  .slider-list :focus {
    outline: none !important;
  }
  .slider-slide {
    overflow: scroll;
    @include media(all-desktop) {
      width: 100% !important;
      padding-right: 50px !important;
    }
    &:focus {
      outline: none !important;
    }
  }
}

//Listing Preview
.listing-preview {
  .listing-img-container {
    .listing-main-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.5s ease;
      backface-visibility: hidden;
      opacity: 1;
      display: block;
    }
    .showing-container {
      padding: 10px;
    }
    .offer-container {
      padding: 10px;
    }
    .action-button {
      height: 40px;
      width: 200px;
      border-radius: 50px;
      background-color: rgba(0, 0, 0, 0.65);
      border: 1px solid #ffffff;
      border-color: white;
      &:hover {
        background-color: rgb(66, 165, 245);
      }
      text-emphasis-color: white;
    }
    .listing-carousel-buttons {
      background-color: transparent;
      border: none;
      cursor: pointer;
      opacity: 0;
      transition: all 0.2s;
    }
    .slider-wrapper {
      &:hover {
        .slick-slider {
          .slick-arrow {
            opacity: 1;
          }
        }
      }
      .slick-slider {
        height: 100%;
        display: flex;
        position: relative;
        .slick-slide {
          height: 100%;
          // Note: this is a hack to fix a height issue where react-slick
          // is adding an extra div around slides
          div:first-child {
            height: 100%;
          }
        }
        &:hover {
          .slick-arrow {
            opacity: 1;
          }
        }
        .slick-list {
          height: 100%;
          width: 100%;
          .slick-track {
            display: flex;
            height: 100% !important;
          }
        }
        .slick-arrow {
          position: absolute;
          transition: all 0.2s;
          opacity: 0;
          z-index: 6;
        }
        .slick-prev {
          left: 0%;
          height: 100%;
          display: flex !important;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .slick-next {
          right: 0%;
          height: 100%;
          display: flex !important;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .listing-text {
    .listing-address-1,
    .listing-mls-id {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #555555;
      margin: 0;
      display: inline-block;
      overflow: hidden;
      @include media(mobile) {
        font-size: 19px;
      }
    }
    .listing-address-1 {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      @include media(mobile) {
        max-width: 270px;
      }
    }
    .listing-address-2 {
      font-size: 18px;
      line-height: 22px;
      color: #555555;
      margin-top: 0;
      margin-bottom: 12px;
    }
    .listing-updated-time {
      line-height: 14px;
      font-size: 14px;
      margin: 0 0 14px;
      color: #555555;
    }
    .listing-details {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      margin: 5px 0;
      color: #555555;
    }
    .listing-investment-values {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      margin: 5px 0;
      color: #555555;
    }
    .commute-times {
      margin-top: 12px;
      .commute-time {
        margin: 5px 5px 0 0;
        padding-left: 0;
        p {
          margin: 1px 0 0 3px;
          color: $text-color-light;
        }
      }
    }
  }
  &.compact {
    .listing-img-container {
      height: 188px;
    }
    .listing-text {
      .listing-address-1,
      .listing-mls-id {
        font-size: 16px;
        line-height: 20px;
      }
      .listing-address-2,
      .listing-updated-time {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 7px;
      }
      .listing-details,
      .listing-investment-values {
        font-size: 12px;
        line-height: 15px;
        margin: 6px 0;
      }
      .listing-pipe {
        color: #bdbdbd;
        padding: 0 2px;
      }
    }
  }
}

// FullCalendar style override

.fc-prev-button.fc-button.fc-button-primary {
  background-color: #463264 !important;
  border-radius: 10px;
  margin-right: 0.5px !important;
}
.fc-next-button.fc-button.fc-button-primary {
  background-color: #463264 !important;
  border-radius: 10px;
  margin-left: 0.5px !important;
}

.fc-today-button.fc-button.fc-button-primary {
  background-color: #463264 !important;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  &:disabled {
    background-color: grey !important;
    color: #bdbdbd;
    opacity: 1;
  }
}

.fc-toolbar-title {
  font-family: 'Sharp Grotesk Semibold', sans-serif;
  @include media(mobile) {
    font-size: 16px !important;
  }
}
.fc-dayGridMonth-button.fc-button.fc-button-primary {
  background-color: grey !important;
  color: #bdbdbd;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  @include media(mobile) {
    font-size: 12px;
    padding: 5px;
  }
  &.fc-button-active {
    background-color: #463264 !important;
  }
}
.fc-timeGridWeek-button.fc-button.fc-button-primary {
  background-color: grey !important;
  color: #bdbdbd;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  @include media(mobile) {
    font-size: 12px;
    padding: 5px;
  }
  &.fc-button-active {
    background-color: #463264 !important;
  }
}

.fc-timeGridDay-button.fc-button.fc-button-primary {
  background-color: grey !important;
  color: #bdbdbd;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  @include media(mobile) {
    font-size: 12px;
    padding: 5px;
  }
  &.fc-button-active {
    background-color: #463264 !important;
  }
}
.fc .fc-toolbar.fc-header-toolbar {
  @include media(mobile) {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

.fc .fc-daygrid-day.fc-day-past {
  background-color: rgba(208, 208, 208, 0.3);
}
.fc .fc-timegrid-col.fc-day-past {
  background-color: rgba(208, 208, 208, 0.3);
}

.fc-daygrid-event-dot,
.fc-event-time {
  display: none;
}
.fc-v-event .fc-event-title {
  font-size: 10px;
  padding: 5px;
  @include media(mobile) {
    font-size: 6px;
    padding: 0;
  }
}
.fc-daygrid-dot-event .fc-event-title {
  padding: 5px;
  float: left;
  clear: none;
  white-space: pre-line;
}

//Simple Listing Slider
.simple-slider-wrapper {
  &:hover {
    .slick-slider {
      .slick-arrow {
        opacity: 1;
      }
    }
  }
  .slick-slider {
    height: 100%;
    display: flex;
    position: relative;
    &:hover {
      .slick-arrow {
        opacity: 1;
      }
    }
    .slick-list {
      height: 100%;
      width: 100%;
      .slick-track {
        display: flex;
        height: 100% !important;
        .slick-slide div {
          height: 100%;
        }
      }
    }
    .slick-arrow {
      position: absolute;
      transition: all 0.2s;
      opacity: 0;
      z-index: 6;
      @include media(mobile) {
        opacity: 1;
      }
    }
    .slick-prev {
      left: 0%;
      height: 100%;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .slick-next {
      right: 0%;
      height: 100%;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

//Swiper
.swiper-carousel {
  .slider-list {
    height: 100% !important;
  }
  .slider-slide {
    height: 100% !important;
  }
}
.home-inner {
  &.loading-container {
    background: rgba(0, 0, 0, 0.25);
  }
  .image-container {
    .background-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .new-home-sidebar {
    z-index: 999;
    width: 120px;
    position: absolute;
    right: 0;
    @include media(mobile) {
      width: 80px;
    }
    svg {
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
      background: rgba(0, 0, 0, 0.25);
      cursor: pointer;
      transition: all ease-in-out 0.2s;
      font-size: 80px;
      border: 3px solid white;
      @include media(mobile) {
        font-size: 50px;
        border: 2px solid white;
      }
      &:hover {
        box-shadow: none;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .home-content {
    .open-house {
      height: 43px;
      width: 115px;
      background: url('/static/img/open_house_marker.png') no-repeat 0 0;
      background-size: 100%;
      margin: 0 auto;
    }
  }
  .listing-open-houses {
    border: 2px solid white;
    border-radius: 5px;
    margin: 0 auto 15px;
    max-width: 200px;
    h2 {
      text-align: center;
    }
    ul {
      padding-left: 0;
      margin: 0 auto 10px;
      li {
        list-style: none;
        text-align: center;
        margin-bottom: 3px;
      }
    }
  }
}

//Sidebar Extension
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;400;600;700&display=swap');
