@import '../abstracts/mixins';

.welcomeDialog {
  .welcomeDialogCloseButton {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 3;
  }
  .welcomeDialogInner {
    position: relative;
    flex: 1;
    padding: 0;
    @include media(gt-mobile) {
      width: 720px;
    }
    .welcomeDialogPrompt {
      z-index: 2;
      .party-icon {
        width: 32px;
        height: 32px;
        background-image: url('/static/img/welcome-dialog/emoji_confetti_mobile@2x.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
      }
      .scheduleCallIcon {
        font-size: 32px;
        margin: 0 auto;
      }
      .phoneIcon {
        width: 28px;
        height: 50px;
        background-image: url('/static/img/welcome-dialog/emoji_phone_web2x.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: 0 auto 15px;
      }
      h1 {
        font-size: 63px;
        margin: 0;
        color: #2c2c2c;
        @include media(mobile) {
          font-size: 45px;
        }
      }
      p {
        font-size: 24px;
        line-height: 36px;
        margin-left: auto;
        margin-right: auto;
        color: #2c2c2c;
      }
      p.areYouReady {
        margin-bottom: 30px;
      }
      p.callText {
        max-width: 420px;
        @include media(mobile) {
          max-width: 300px;
          font-size: 16px;
          line-height: 30px;
        }
      }
      .buttonsContainer {
        max-width: 450px;
        margin: 0 auto;
        @include media(mobile) {
          max-width: 220px;
        }
        .buttonWrapper {
          text-decoration: none;
        }
      }
      .welcomeButton {
        text-transform: initial;
        &.first {
          margin-bottom: 20px;
        }
      }
      .phoneWrapper {
        text-decoration: none;
        color: #2c2c2c;
        h1 {
          &:hover {
            color: #1565c0;
          }
        }
        &:hover {
          color: #1565c0;
        }
      }
      .subtext {
        font-size: 14px;
        margin: 15px auto 0;
        .closeModalText {
          text-transform: initial;
          padding: 6px 2px 11px;
          text-decoration: underline;
          line-height: 13px;
          color: #2c2c2c;
        }
      }
    }
    .welcomeDialogBg {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      .bgLower {
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 0;
        background-image: url('/static/img/welcome-dialog/gradient_bar_modal@2x.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        @include media(mobile) {
          background-image: url('/static/img/welcome-dialog/modal_gradient_mobile@2x.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .accountCreatedDialogInner {
    padding: 0;
    flex: 1;
    width: 900px;
    height: 519px;
    position: relative;
    background-image: url('/static/img/welcome-dialog/account_created_desktop.png');
    background-repeat: no-repeat;
    background-size: 60% 18%;
    background-position: bottom;
    background-position-x: right;
    @include media(mobile) {
      background-image: url('/static/img/welcome-dialog/account_created_mobile.png');
      background-repeat: no-repeat;
      background-size: 100%;
    }
    @include media(mobile) {
      width: 100%;
    }
    @include media(tablet) {
      width: 672px;
    }
    .accountCreatedHeader {
      position: relative;
      background-image: url('/static/img/home/dialog_wave.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      @include media(tablet-small) {
        background-image: url('/static/img/home/dialog_wave_sm.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      @include media(mobile) {
        background-image: url('/static/img/home/dialog_wave_sm.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .accountCreatedHeaderText {
        max-width: 300px;
        margin-bottom: 50px;
        text-align: left;
        @include media(mobile) {
          margin-top: 40px;
          text-align: center;
        }
        h2 {
          font-size: 30px;
          font-weight: 900;
          color: white;
          margin: 0 0 10px;
        }
        p {
          color: white;
          margin: 0;
          font-size: 16px;
          line-height: 25px;
          @include media(mobile) {
            font-size: 14px;
          }
        }
      }
    }
    .accountCreatedStoreContainer {
      padding: 20px;
      @include media(mobile) {
        height: 400px;
      }
      .linkContainer {
        padding: 0px;
        margin: 0px 0 10px 0;
        background-color: transparent;
      }
      .getStartedButton {
        display: block;
      }
      .appButtonsContainer {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 390px !important;
        position: relative;
        z-index: 1;
        .iosButton {
          background-image: url('/static/img/home/apple.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 210px;
          height: 69px;
          text-indent: -99999px;
        }
        .androidButton {
          width: 210px;
          height: 62px;
          background-image: url('/static/img/home/google_play.png');
          background-repeat: no-repeat;
          background-size: cover;
          text-indent: -99999px;
        }
      }
    }
  }
}
