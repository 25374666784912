@import '../abstracts/variables';
@import '../abstracts/mixins';

.dashboardContainer {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  @include media(tablet) {
    padding: 30px;
    flex-direction: column;
  }
  @include media(mobile) {
    padding: 20px;
    flex-direction: column;
  }
  .heading {
    font-family: 'Poppins', sans-serif;
    padding: 15px;
    font-size: 24px;
    @include media(mobile) {
      font-size: 16px;
      padding: 15px 0;
    }
    @include media(tablet) {
      font-size: 24px;
    }
  }

  .verifyContainer {
    background: rgba(255, 215, 80, 0.2);
    border: 1px solid #826441;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    margin: 23px 0px;
    padding: 16px 20px;
    width: auto;

    @include media(desktop-xl) {
      margin-right: 7%;
    }
    .verifyText {
      color: #2d2332;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      @include media(mobile) {
        font-size: 3.5vw;
      }
    }
    .verifyInfo {
      color: #2d2332;
      font-weight: normal;
      font-size: 12px;
      margin: 0;
      @include media(mobile) {
        font-size: 2.5vw;
      }
    }
    .verifyButton {
      color: #faf8fb;
      background: #816646;
      border-radius: 16px;
      align-items: center;
      padding: 8px 16px;
      text-transform: none;
      height: 40px;
      font-weight: 600;
      font-size: 16px;
      @include media(mobile) {
        font-size: 3vw;
        height: 150%;
      }
    }
  }
  .componentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media(desktop-xl) {
      margin-right: 7%;
    }
    @include media(tablet) {
      flex-direction: column;
      align-items: center;
    }

    @include media(mobile) {
      flex-direction: column;
      align-items: center;
    }

    .leftComponents {
      display: flex;
      flex-direction: column;
      @include media(desktop-xl) {
        width: 73%;
      }
    }

    .rightComponents {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      @include media(tablet) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: auto;
      }
      @include media(mobile) {
        width: 100%;
      }
    }
  }
}
