@import '../abstracts/mixins';
@import '../abstracts/variables';

$mainFont: 'Sharp Grotesk Semibold', sans-serif;

.five-star {
  width: 195px;
  height: 34px;
  margin-bottom: 5px;
}

.section-margin {
  margin-bottom: 100px;

  @include media(mobile) {
    margin-bottom: 90px;
  }
}

.home-container,
.about-us {
  background: #faf5f0;

  .link-container {
    padding: 0px;
    margin: 0px;
    background-color: transparent;
  }

  h1 {
    color: #323232;
    letter-spacing: 0;
    line-height: 57px;
    text-align: center;
    margin: 0;
    font-size: 48px;
    font-weight: 900;
  }

  h2 {
    text-align: center;
    margin: 0 auto;
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
    color: #ffffff;

    @include media(mobile) {
      font-size: 30px;
    }
  }

  h3 {
    color: $text-color;
    font-size: 36px;
    margin: 0px;

    &.sub {
      margin-bottom: 25px;
    }

    @include media(mobile) {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }

  h4 {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
  }

  p {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
    text-align: left;

    @include media(tablet) {
      font-size: 16px;
      line-height: 24px;
    }

    @include media(mobile) {
      font-size: 16px;
      line-height: 24px;
    }

    &.section-sub-header {
      margin-bottom: 25px;

      @include media(mobile) {
        line-height: 22px;
      }
    }
  }

  .hero-banner {
    width: 100%;
    height: auto;
    padding: 60px 120px;
    background-color: #ecf6fe;

    @media only screen and (min-width: 950px) and (max-width: 1042px) {
      padding: 60px 100px;
    }

    @include media(tablet) {
      padding: 80px 80px 32px 80px;
    }

    @include media(mobile) {
      padding: 72px 16px 16px 16px;
    }

    .hero-container {
      max-width: 1184px;
      margin: 0px auto;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media(tablet) {
        flex-direction: column;
      }

      @include media(mobile) {
        flex-direction: column;
      }

      .hero-banner-textbox {
        width: 580px;

        @media only screen and (max-width: 1350px) {
          width: 60%;
        }

        @include media(tablet) {
          width: 100%;
        }

        @include media(mobile) {
          width: 100%;
        }

        .first-break {
          @include media(tablet) {
            display: none !important;
          }

          @include media(no-desktop-small) {
            display: none !important;
          }

          @include media(desktop) {
            display: none !important;
          }

          @include media(desktop-xl) {
            display: none !important;
          }
        }

        .second-break {
          @media only screen and (min-width: 950px) and (max-width: 1042px) {
            display: none !important;
          }

          @include media(mobile) {
            display: none !important;
          }
        }

        .hero-location {
          display: flex;
          align-items: center;
          margin-top: 40px;

          p {
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;

            @media only screen and (min-width: 950px) and (max-width: 1042px) {
              font-size: 15px;
            }

            @include media(mobile) {
              font-size: 13px;
            }
          }
        }

        h2 {
          color: #323232;
          letter-spacing: 0;
          line-height: 43.2px;
          text-align: left;
          margin-bottom: 16px;

          @media only screen and (min-width: 530px) and (max-width: 1200px) {
            font-size: 36px;
          }

          @include media(mobile) {
            color: black;
            font-size: 24px;
            line-height: 22.8px;
            margin-left: 10px;
            text-align: left;
          }
        }

        h3 {
          color: #323232;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 28px;
          margin-bottom: 24px;
          text-align: left;

          @media only screen and (min-width: 530px) and (max-width: 1200px) {
            font-size: 18px;
          }

          @include media(mobile) {
            font-size: 16px;
            line-height: 24px;
            margin-left: 10px;
            margin-top: 15px;
            text-align: left;
          }
        }
      }

      .hero-banner-brand {
        width: 50%;
        height: 515px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('/static/img/home/hero-section-image.png');

        @include media(tablet) {
          width: 100%;
          height: 625px;
        }

        @include media(mobile) {
          width: 100%;
          height: 385px;
        }
      }
    }
  }

  .home-hero-banner {
    width: 100%;
    height: auto;
    padding: 177px 143px 187px 100px;
    background-color: #463264;
    justify-content: left;
    align-items: center;
    overflow-x: clip;
    position: relative;
    text-align: justify;

    @media only screen and (min-width: 950px) and (max-width: 1310px) {
      padding: 60px 100px;
    }

    @include media(tablet) {
      padding: 60px 80px 60px;
    }

    @include media(mobile) {
      padding: 48px 16px 48px;
    }

    &.sell-page-hero {
      padding: 127px 143px 64px 100px;

      @media only screen and (min-width: 950px) and (max-width: 1310px) {
        padding: 60px 100px;
      }

      @include media(tablet) {
        padding: 60px 80px 60px;
      }

      @include media(mobile) {
        padding: 48px 16px 48px;
      }
    }

    .home-hero {
      width: 50%;
      max-width: 604px;
      min-width: 604px;

      @include media(tablet) {
        width: 604px;
      }

      @include media(mobile) {
        min-width: 0px;
        width: 100%;
      }

      h2 {
        font-size: 36px;
        font-weight: 700;
        line-height: 43px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 16px;
      }

      .home-hero-heading-text {
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-weight: 600;
        font-size: 52px;
        line-height: 64px;
        text-align: initial;

        @include media(mobile) {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .indexed-home-hero-heading-text {
        @include media(tablet) {
          font-size: 32px;
          line-height: 40px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1160px) {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .home-hero-text {
        text-align: justify;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        color: #ffffff;

        @include media(mobile) {
          font-size: 16px;
        }

        .home-hero-text-bold {
          display: block;
          font-weight: 700;
        }
      }

      .indexed-home-hero-text {
        @include media(tablet) {
          font-size: 16px;
          line-height: 32px;
        }

        @include media(mobile) {
          margin-right: 10%;
        }

        @media only screen and (min-width: 950px) and (max-width: 1160px) {
          font-size: 16px;
          line-height: 32px;
        }
      }

      .select-container {
        width: 100%;
        max-width: 370px;
        height: 56px;
        margin: 32px 32px 32px 0px;
        background-color: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .select-label {
          font-size: 14px;
          z-index: 10;
          transform: translate(5px, 7.5px) scale(1);
        }

        .text-field {
          width: 332px;
        }

        .MuiSelect-select {
          text-align: left;
          font-size: 18px;
          line-height: 21.6px;
        }

        .menu-item {
          font-size: 18px;
          line-height: 21.6px;
        }
      }

      .home-hero-button {
        width: 370px;
        height: 56px;
        color: #ffffff;
        background-color: #ff7350;
        margin-bottom: 24px;
        border-radius: 30px;

        &:hover {
          background-color: #3d86ef;
        }

        @include media(mobile) {
          width: 100%;
        }
      }

      .home-hero-image {
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
        width: 680px;
        @media only screen and (max-width: 1600px) {
          width: 680px;
        }

        @media only screen and (min-width: 1300px) and (max-width: 1490px) {
          width: 680px;
          right: -200px;
        }

        @media only screen and (min-width: 769px) and (max-width: 1300px) {
          display: none;
        }

        @include media(tablet) {
          margin-top: 36px;
          position: relative;
          right: -15%;
          top: 0px;
          display: flex;
          z-index: 0;
        }

        @include media(mobile) {
          margin-top: 36px;
          position: relative;
          right: -15px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 0;
        }

        .home-hero-image-two {
          width: 680px;
          height: 100%;

          @include media(mobile) {
            width: 100%;
            height: 400px;
          }
        }

        .sell-hero-image-desktop {
          margin: 150px 0px 0px 0px;

          @include media(tablet) {
            display: none;
          }

          @include media(mobile) {
            display: none;
          }
        }

        .sell-hero-image-tablet {
          margin: 150px 0px 0px 0px;
          display: none;

          @include media(tablet) {
            display: block;
          }
        }

        .sell-hero-image-mobile {
          margin: 75px 0px 0px;
          height: 100%;
          display: none;

          @include media(mobile) {
            display: block;
          }
        }

        .indexed-home-hero-mobile {
          transform: scale(1.2);

          @media only screen and (min-width: 462px) and (max-width: 529px) {
            transform: scale(1.3);
          }
        }

        .indexed-landing-page-hero-image-desktop {
          margin: 0px;

          @media only screen and (min-width: 950px) and (max-width: 1160px) {
            margin-top: -10px;
          }
        }
      }

      .sell-hero-image {
        @include media(mobile) {
          margin-top: 0px;
          right: 3%;
        }

        @include media(tablet) {
          margin-top: 0px;
          right: 5%;
        }

        @media only screen and (min-width: 1300px) and (max-width: 1490px) {
          right: -20px;
        }

        @media only screen and (min-width: 1151px) and (max-width: 1300px) {
          display: block !important;
          right: -110px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1150px) {
          display: block !important;
          right: -300px;
        }

        @media only screen and (min-width: 462px) and (max-width: 529px) {
          display: block !important;
          right: -8% !important;
        }
      }

      .indexed-landing-page-image {
        z-index: 0;
        @include media(tablet) {
          position: relative;
          right: -50vw;
          top: -303px;
          transform: scale(0.75);
        }

        @media only screen and (min-width: 821px) and (max-width: 949px) {
          display: block !important;
          right: -57vw !important;
        }

        @media only screen and (min-width: 769px) and (max-width: 820px) {
          display: block !important;
          right: -53vw !important;
        }

        @media only screen and (min-width: 950px) and (max-width: 1300px) {
          display: block !important;
          right: -200px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1160px) {
          margin-top: 0px !important;
        }
      }

      .home-hero-image-container-three {
        position: absolute;
        top: 0px;
        z-index: 1;

        @media only screen and (min-width: 950px) and (max-width: 1050px) {
          margin-top: 36px;
          position: relative;
          right: 0px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include media(tablet) {
          margin-top: 36px;
          position: relative;
          right: 0px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include media(mobile) {
          margin-top: 36px;
          position: relative;
          right: 0px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .indexed-landing-page-hero {
      @include media(tablet) {
        min-width: 430px !important;
        max-width: 430px !important;
      }

      @include media(mobile) {
        min-width: 410px !important;
        max-width: 410px !important;
      }

      @media only screen and (min-width: 950px) and (max-width: 1300px) {
        margin: 0px;
      }
    }

    .press-container-sell-page {
      margin-top: 80px;
      margin-bottom: 80px;
      justify-content: initial;

      @include media(mobile) {
        margin: 0px;
      }
    }

    .indexed-home-press-container {
      @include media(tablet) {
        flex-direction: row;
        position: relative;
        top: -450px;
        margin-top: 0px !important;
      }

      @media only screen and (min-width: 950px) and (max-width: 1160px) {
        flex-direction: row;
        position: relative;
        top: 0px;
        margin-top: 0px !important;
      }
    }

    .press-container-left-aligned {
      margin-top: 30px;
      justify-content: left;
    }
  }

  .home-hero-banner-light {
    width: 100%;
    height: auto;
    padding: 177px 143px 187px 100px;
    justify-content: left;
    align-items: center;
    position: relative;
    text-align: justify;
    margin-bottom: 30px;

    @media only screen and (min-width: 950px) and (max-width: 1310px) {
      padding: 120px 24px;
    }

    @include media(tablet) {
      padding: 60px 46px 60px 48px;
      margin-bottom: 0px;
    }

    @include media(mobile) {
      padding: 48px 16px 48px;
      margin-bottom: 0px;
    }

    .home-hero {
      width: 50%;
      max-width: 612px;
      min-width: 612px;

      @media only screen and (min-width: 950px) and (max-width: 1300px) {
        min-width: 420px;
      }

      @include media(tablet) {
        width: 604px;
      }

      @include media(mobile) {
        min-width: 0px;
        width: 100%;
      }

      h2 {
        font-size: 36px;
        font-weight: 700;
        line-height: 43px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 16px;
      }

      .home-hero-heading-text {
        color: #2d2332;
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-weight: 600;
        font-size: 48px;
        line-height: 64px;
        text-align: initial;

        @include media(mobile) {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .indexed-home-hero-heading-text {
        @include media(tablet) {
          font-size: 32px;
          line-height: 40px;
        }

        @media only screen and (max-width: 600px) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .home-hero-text {
        text-align: justify;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        color: #2d2332;

        @include media(mobile) {
          font-size: 16px;
        }

        .home-hero-text-bold {
          display: block;
          font-weight: 700;
        }
      }

      .indexed-home-hero-text {
        @include media(tablet) {
          font-size: 16px;
          line-height: 32px;
        }

        @include media(mobile) {
          margin-right: 10%;
        }

        @media only screen and (min-width: 950px) and (max-width: 1160px) {
          font-size: 16px;
          line-height: 32px;
        }

        .indexed-home-hero-break {
          display: none;

          @media only screen and (min-width: 950px) and (max-width: 1300px) {
            display: block;
          }

          @include media(tablet) {
            display: block;
          }
        }
      }

      .select-container {
        width: 100%;
        max-width: 370px;
        height: 56px;
        margin: 32px 32px 32px 0px;
        background-color: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .select-label {
          font-size: 14px;
          z-index: 10;
          transform: translate(5px, 7.5px) scale(1);
        }

        .text-field {
          width: 332px;
        }

        .MuiSelect-select {
          text-align: left;
          font-size: 18px;
          line-height: 21.6px;
        }

        .menu-item {
          font-size: 18px;
          line-height: 21.6px;
        }
      }

      .home-hero-button {
        width: 370px;
        height: 56px;
        color: #ffffff;
        background-color: #ff7350;
        margin-bottom: 24px;
        border-radius: 30px;

        &:hover {
          background-color: #3d86ef;
        }

        @include media(mobile) {
          width: 100%;
        }
      }

      .home-hero-image {
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
        width: 680px;
        @media only screen and (min-width: 1439px) and (max-width: 1600px) {
          width: 680px;
        }

        @media only screen and (min-width: 769px) and (max-width: 1300px) {
          display: none;
        }

        @include media(tablet) {
          margin-top: 36px;
          position: relative;
          right: -15%;
          top: 0px;
          display: flex;
          z-index: 0;
        }

        @include media(mobile) {
          margin-top: 36px;
          position: relative;
          right: -15px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 0;
        }

        .home-hero-image-two {
          width: 100%;
          height: 100%;

          @include media(mobile) {
            width: 100%;
            height: 400px;
          }
        }

        .sell-hero-image-desktop {
          margin: 150px 0px 0px 0px;

          @include media(mobile) {
            display: none;
          }

          @include media(tablet) {
            display: none;
          }
        }

        .sell-hero-image-tablet {
          margin: 150px 0px 0px 0px;
          display: none;

          @include media(tablet) {
            display: block;
          }
        }

        .sell-hero-image-mobile {
          margin: 75px 0px 0px;
          height: 100%;
          display: none;

          @include media(mobile) {
            display: block;
          }
        }

        .indexed-home-hero-mobile {
          transform: scale(1.2);

          @media only screen and (min-width: 462px) and (max-width: 529px) {
            transform: scale(1.3);
          }
        }

        .indexed-landing-page-hero-image-desktop {
          margin: 0px;

          @media only screen and (min-width: 950px) and (max-width: 1160px) {
            margin-top: -10px;
          }
        }

        .indexed-landing-page-hero-image-tablet {
          margin: 0px;
        }
      }

      .sell-hero-image {
        @include media(mobile) {
          margin-top: 0px;
          right: 3%;
        }

        @include media(tablet) {
          margin-top: 0px;
          right: 5%;
        }

        @media only screen and (min-width: 950px) and (max-width: 1150px) {
          display: block !important;
          right: -300px;
        }

        @media only screen and (min-width: 1151px) and (max-width: 1300px) {
          display: block !important;
          right: -110px;
        }

        @media only screen and (min-width: 462px) and (max-width: 529px) {
          display: block !important;
          right: -8% !important;
        }
      }

      .indexed-landing-page-image {
        z-index: 0;
        @include media(tablet) {
          position: absolute;
          right: 0px;
          top: 0px;
        }

        @media only screen and (max-width: 600px) {
          width: 300px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1300px) {
          display: block !important;
          right: -200px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1160px) {
          margin-top: 0px !important;
        }
      }

      .home-hero-image-container-three {
        position: absolute;
        top: 0px;
        z-index: 1;

        @media only screen and (min-width: 950px) and (max-width: 1050px) {
          margin-top: 36px;
          position: relative;
          right: 0px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include media(tablet) {
          margin-top: 36px;
          position: relative;
          right: 0px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include media(mobile) {
          margin-top: 36px;
          position: relative;
          right: 0px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .hero-location {
        display: flex;

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
        }
      }

      .hero-search {
        position: relative;
        margin: 48px 60px 0px 0px;

        @include media(mobile) {
          margin: 48px 0px 0px 0px;
        }

        .hero-search-container {
          margin-top: 52px;
          display: flex;
          align-items: center;

          @include media(tablet) {
            margin-top: 20px;
          }

          @include media(mobile) {
            margin-top: 20px;
          }

          .hero-search-text {
            color: #ffffff;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }

    .indexed-landing-page-hero {
      @include media(tablet) {
        min-width: 100% !important;
        max-width: 100% !important;
      }

      @include media(mobile) {
        min-width: 100% !important;
        max-width: 100% !important;
      }

      @media only screen and (min-width: 950px) and (max-width: 1300px) {
        margin: 0px;
      }
    }

    .hero-location-map {
      display: flex;
      align-items: center;

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;

        @include media(mobile) {
          margin-top: 8px;
          text-align: left;
        }
      }
    }

    .press-container {
      display: flex;
      margin-top: 150px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      @include media(tablet) {
        flex-direction: column;
        justify-content: space-between;
      }

      @include media(mobile) {
        flex-direction: column;
        justify-content: space-between;
      }

      .press-icon {
        margin: 0 45px;

        @media only screen and (max-width: 768px) {
          margin: 0px 15px;
        }

        @include media(mobile) {
          margin: 20px;
        }

        @include media(tablet) {
          margin: 20px;
        }
      }

      .press-icon-sell-page {
        margin: 0px 50px 0px 0px;

        @include media(tablet) {
          margin: 50px 0px 0px;
        }

        @include media(mobile) {
          margin: 50px 0px 0px;
        }
      }

      .business-insider-icon {
        @include media(tablet) {
          margin: 20px;
          width: 90px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1160px) {
          margin: 20px;
          width: 90px;
        }
      }

      .boston-globe-icon {
        @include media(tablet) {
          margin: 20px;
          width: 130px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1160px) {
          margin: 20px;
          width: 130px;
        }
      }

      .inman-icon {
        @include media(tablet) {
          margin: 20px;
          width: 90px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1160px) {
          margin: 20px;
          width: 90px;
        }
      }
    }

    .press-container-sell-page {
      margin-top: 80px;
      justify-content: initial;

      @include media(mobile) {
        margin: 0px;
      }
    }

    .indexed-home-press-container {
      @include media(tablet) {
        flex-direction: row;
        position: relative;
        top: -450px;
        margin-top: 0px !important;
      }

      @media only screen and (min-width: 950px) and (max-width: 1160px) {
        flex-direction: row;
        position: relative;
        top: 0px;
        margin-top: 0px !important;
      }
    }

    .press-container-left-aligned {
      margin-top: 30px;
      justify-content: left;
    }
  }

  .video-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
  }

  .press-container {
    display: flex;
    margin-bottom: 150px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @include media(tablet) {
      margin-bottom: 110px;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    @include media(mobile) {
      flex-direction: column;
      justify-content: space-between;
    }

    .press-icon {
      margin: 0 45px;

      @media only screen and (max-width: 768px) {
        margin: 0px 15px;
      }

      @include media(mobile) {
        margin: 20px;
      }

      @include media(tablet) {
        margin: 20px;
      }
    }

    .press-icon-sell-page {
      margin: 0px 50px 0px 0px;

      @include media(tablet) {
        margin: 50px 0px 0px;
      }

      @include media(mobile) {
        margin: 50px 0px 0px;
      }
    }

    .business-insider-icon {
      margin-left: 20px;

      @include media(tablet) {
        margin: 20px;
        width: 90px;
      }

      @media only screen and (min-width: 950px) and (max-width: 1160px) {
        margin: 20px;
        width: 90px;
      }

      @media only screen and (max-width: 600px) {
        margin-left: 20px;
      }
    }

    .boston-globe-icon {
      @include media(tablet) {
        margin: 20px;
        width: 130px;
      }

      @media only screen and (min-width: 950px) and (max-width: 1160px) {
        margin: 20px;
        width: 130px;
      }
    }

    .inman-icon {
      @include media(tablet) {
        margin: 20px;
        width: 90px;
      }

      @media only screen and (min-width: 950px) and (max-width: 1160px) {
        margin: 20px;
        width: 90px;
      }
    }
  }

  .sell-page-hero {
    @media only screen and (min-width: 950px) and (max-width: 1200px) {
      padding: 60px 30px;
    }
  }

  .bottom-ellipse {
    width: 100%;
    margin-top: -1px;
  }

  .bottom-ellipse-desktop-tablet {
    display: block;

    @include media(mobile) {
      display: none;
    }
  }

  .bottom-ellipse-mobile {
    display: none;
    margin-top: -1px;

    @include media(mobile) {
      display: block;
    }
  }

  .bottom-reverse-ellipse {
    width: 100%;
    z-index: 1;
    position: relative;
  }

  .bottom-reverse-ellipse-responsive {
    width: 130%;
    display: block;
    left: -240px;
    top: 20px;

    @include media(mobile) {
      width: 150%;
      left: -100px;
      top: 22px;
    }

    @include media(tablet) {
      top: -450px;
      left: -135px;
    }

    @media only screen and (min-width: 1400px) and (max-width: 1620px) {
      left: -200px;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1399px) {
      width: 140%;
    }

    @media only screen and (min-width: 950px) and (max-width: 1199px) {
      left: -150px;
    }
  }

  .search-page-banner {
    width: 100%;
    height: auto;
    background-color: #463264;
    justify-content: left;
    align-items: center;
    overflow-x: clip;
    position: relative;
    text-align: justify;

    @include media(mobile) {
      padding: 45px 0px 0px 16px;
      padding-right: 0px;
    }

    .home-hero {
      width: 100%;
      display: flex;
      padding: 180px 0px 0px 100px;

      @include media(tablet) {
        padding: 45px 0px 0px 32px;
      }

      @include media(mobile) {
        padding: 45px 0px 0px 16px;
      }

      .hero-text-container {
        width: 605px;

        @include media(mobile) {
          width: 100%;
          margin-right: 32px;
        }

        h2 {
          font-size: 36px;
          font-weight: 700;
          line-height: 43px;
          letter-spacing: 0px;
          text-align: center;
          margin-bottom: 24px;
        }

        .home-hero-heading-text {
          font-family: 'Sharp Grotesk Semibold', sans-serif;
          font-weight: 600;
          font-size: 52px;
          line-height: 64px;
          text-align: initial;
          @include media(desktop-small) {
            font-size: 46px;
          }
          @include media(tablet) {
            font-size: 32px;
            line-height: 40px;
          }

          @include media(mobile) {
            font-size: 32px;
            line-height: 40px;
          }
        }
      }

      .home-hero-image {
        width: 50%;
        height: auto;
        margin-left: auto;

        @include media(mobile) {
          display: none;
        }
      }

      .hero-location {
        display: flex;
        align-items: center;

        p {
          color: #ffffff;
          font-size: 16px;
          font-family: 'Poppins', sans-serif;
          font-size: 21px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;

          @include media(mobile) {
            font-size: 18px;
          }
        }
      }

      .hero-search {
        position: relative;
        margin: 48px 60px 0px 0px;

        @include media(mobile) {
          margin: 48px 0px 0px 0px;
        }

        .hero-search-container {
          margin-top: 52px;
          display: flex;
          align-items: center;

          @include media(tablet) {
            margin-top: 20px;
          }

          @include media(mobile) {
            margin-top: 20px;
          }

          .hero-search-text {
            color: #ffffff;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }

    .press-container {
      display: flex;
      margin: 75px 0px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      @include media(mobile) {
        margin: 50px 0px;
        flex-direction: column;
        justify-content: space-between;
      }

      .press-icon {
        margin: 0 45px;

        @media only screen and (max-width: 768px) {
          margin: 0px 15px;
        }

        @include media(mobile) {
          margin: 20px;
        }

        @include media(tablet) {
          margin: 20px;
        }
      }
    }

    .press-container-searchHomes {
      @include media(mobile) {
        display: none;
      }
    }

    .press-container-searchHomes-mobile {
      display: none;

      @include media(mobile) {
        display: flex;
      }
    }

    .home-hero-preview {
      display: none;

      @include media(mobile) {
        display: block;
        width: 100%;
        height: 350px;
        margin-top: 24px;
        background-image: url('/static/img/landing_pages/search-page-hero-mobile.png');
        background-repeat: no-repeat;
      }
    }
  }

  .section-personalization {
    background-color: #463264;
    width: 100%;
    padding: 0px 0px 0px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media(tablet) {
      padding: 0px 0px 0px 32px;
    }

    @include media(mobile) {
      flex-direction: column;
      padding: 75px 0px 0px 0px;
    }

    .personalization-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 50%;

      @include media(mobile) {
        max-width: 100%;
        padding: 0px 32px 48px 32px;
      }

      .personalization-category {
        color: #ffd750;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
      }

      .personalization-heading {
        color: #ffffff;
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        margin: 16px 0px 24px 0px;

        @include media(tablet) {
          font-size: 32px;
          line-height: 40px;
        }

        @include media(mobile) {
          font-size: 32px;
          line-height: 40px;
        }
      }

      @media only screen and (max-width: 1240px) and (min-width: 950px) {
        .personalization-heading {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .personalization-text {
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;

        @include media(tablet) {
          width: 330px;
        }

        @include media(mobile) {
          width: 100%;
          font-size: 16px;
          line-height: 32px;
        }
      }

      .personalization-button-container {
        display: flex;
        margin-top: 24px;

        @include media(tablet) {
          flex-direction: column;
          align-items: flex-start;
        }

        @include media(mobile) {
          flex-direction: column;
          align-items: flex-start;
        }

        .quiz-button-two {
          margin-left: 16px;

          @include media(tablet) {
            margin-left: 0px;
            margin-top: 24px;
          }

          @include media(mobile) {
            margin-left: 0px;
            margin-top: 24px;
          }
        }
      }
    }

    .personalization-preview {
      width: 650px;
      height: 610px;
      background-image: url('/static/img/landing_pages/search-page-client-desktop.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      @include media(tablet) {
        margin-top: 0px;
        width: 372px;
        height: 664px;
        background-image: url('/static/img/landing_pages/search-page-client-tablet-mini.png');
      }

      @include media(mobile) {
        width: 100%;
        height: 600px;
        background-image: url('/static/img/landing_pages/search-page-client-mobile.png');
      }

      .kitchen-blob {
        position: absolute;
        top: -50px;
        left: -20px;

        @include media(tablet) {
          left: -50px;
        }

        @include media(mobile) {
          display: none;
        }
      }

      .park-blob {
        position: absolute;
        top: -75px;
        right: 0px;

        @include media(mobile) {
          display: none;
        }
      }

      .host-blob {
        position: absolute;
        bottom: -50px;
        right: 50px;

        @include media(mobile) {
          display: none;
        }
      }
    }
  }

  .search-design {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 100px 36px 100px;
    overflow: hidden;

    @include media(tablet) {
      padding: 16px 32px;
      padding-top: 80px;
    }

    @include media(mobile) {
      flex-direction: column;
      padding: 32px 16px;
    }

    .search-design-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 55%;

      @include media(tablet) {
        margin-top: 16px;
        margin-left: 32px;
      }

      @include media(mobile) {
        margin-bottom: 48px;
        order: -1;
      }

      .info-category {
        color: #463264;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
      }

      .info-heading {
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 16px;

        @include media(tablet) {
          font-size: 32px;
          line-height: 40px;
        }

        @include media(mobile) {
          font-size: 32px;
          line-height: 40px;
        }
      }

      @media only screen and (max-width: 1240px) and (min-width: 950px) {
        .info-heading {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .design-text-container {
        width: 100%;
        margin-top: 32px;

        @include media(tablet) {
          width: 325px;
        }

        @include media(mobile) {
          width: 100%;
        }

        p {
          color: #2d2332;
          font-family: Poppins, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
        }

        .phone-preview-break-two {
          @media only screen and(max-width: 1040px) and (min-width: 950px) {
            display: none;
          }
        }

        .phone-preview-break-three {
          @media only screen and(max-width: 1040px) and (min-width: 950px) {
            display: none;
          }
        }
      }

      .app-buttons-container {
        width: 100%;
        z-index: 1;
        padding: 0px;
        display: flex;
        justify-content: flex-start;
        margin-top: 24px;

        @include media(tablet) {
          flex-direction: column;
        }

        @include media(mobile) {
          flex-direction: column;
        }

        .ios-button {
          height: 64px;
          padding: 10px 20px;
          border-radius: 5px;
          background-color: #195a50;
          margin-right: 16px;

          @include media(tablet) {
            width: 100%;
            margin-bottom: 24px;
          }

          @include media(mobile) {
            width: 100%;
            margin-bottom: 16px;
          }

          .get-started-button {
            text-decoration: none;
            margin: 0px;
            display: flex;
            justify-content: center;
            align-items: center;

            .button-tag {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 20px;

              @media only screen and (min-width: 950px) and (max-width: 1075px) {
                margin-left: 5px;
              }

              .button-cta {
                display: block;
                text-transform: none;
                color: #ffffff;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
              }

              .button-title {
                color: #ffffff;
                text-transform: capitalize;
                font-size: 18px;
                font-weight: 700;
                line-height: 22px;
                letter-spacing: 0px;
              }
            }
          }
        }

        .android-button {
          height: 64px;
          padding: 0px;
          border-radius: 5px;
          background-color: #195a50;
          padding: 10px 20px;

          @include media(tablet) {
            width: 100%;
            margin-bottom: 24px;
          }

          @include media(mobile) {
            width: 100%;
          }

          .get-started-button {
            text-decoration: none;
            margin: 0px;
            display: flex;
            justify-content: center;
            align-items: center;

            .button-tag {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 20px;

              @media only screen and (min-width: 950px) and (max-width: 1075px) {
                margin-left: 5px;
              }

              .button-cta {
                display: block;
                text-transform: none;
                color: #ffffff;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
              }

              .button-title {
                color: #ffffff;
                text-transform: capitalize;
                font-size: 18px;
                font-weight: 700;
                line-height: 22px;
                letter-spacing: 0px;
              }
            }
          }
        }
      }
    }

    .search-design-preview {
      width: 550px;
      height: 600px;
      background-image: url('/static/img/landing_pages/search-page-app-desktop.png');
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 32px;

      @include media(tablet) {
        width: 400px;
        height: 400px;
        background-image: url('/static/img/landing_pages/search-page-app-tablet.png');
      }

      @include media(mobile) {
        width: 100%;
        height: 400px;
        margin-right: 0px;
        background-image: url('/static/img/landing_pages/search-page-app-mobile.png');
      }
    }

    @media only screen and (max-width: 1240px) and (min-width: 950px) {
      .search-design-preview {
        background-image: url('/static/img/landing_pages/search-page-app-mobile.png');
        width: 320px;
        height: 350px;
        margin-right: 16px;
      }
    }
  }

  .search-dashboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 75px 0px 75px 100px;

    @include media(tablet) {
      padding: 75px 0px 0px 32px;
    }

    @include media(mobile) {
      padding: 86px 0px 24px 32px;
      flex-direction: column;
    }

    .search-home-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 50%;

      @include media(mobile) {
        margin-right: 32px;
        max-width: 100%;
      }

      .dashboard-category {
        color: #463264;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
      }

      .dashboard-heading {
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        margin: 16px 0px 24px 0px;

        @include media(tablet) {
          font-size: 24px;
          line-height: 32px;
        }

        @include media(mobile) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      @media only screen and (max-width: 1240px) and (min-width: 950px) {
        .dashboard-heading {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .dashboard-text {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;

        @include media(tablet) {
          width: 325px;
        }

        @include media(mobile) {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          line-height: 32px;
        }
      }
    }

    .dashboard-preview {
      width: 890px;
      height: 890px;
      background-image: url('/static/img/landing_pages/dashboard-desktop.png');
      background-repeat: no-repeat;
      background-size: cover;

      @include media(tablet) {
        background-image: url('/static/img/landing_pages/dashboard-tablet.png');
        width: 360px;
        height: 380px;
      }

      @include media(mobile) {
        margin-top: 44px;
        background-image: url('/static/img/landing_pages/dashboard-mobile.png');
        width: 100%;
        height: 375px;
      }
    }
  }

  .section-listing {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 75px 0px 75px 100px;

    @include media(tablet) {
      padding: 75px 0px 0px 32px;
    }

    @include media(mobile) {
      flex-direction: column;
      padding: 75px 32px 0px 32px;
    }

    .listing-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 50%;

      @include media(mobile) {
        margin-bottom: 48px;
        max-width: 100%;
      }

      .listing-category {
        color: #463264;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
      }

      .listing-heading {
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        margin: 16px 0px 24px 0px;

        @include media(tablet) {
          font-size: 32px;
          line-height: 40px;
        }

        @include media(mobile) {
          font-size: 31px;
          line-height: 40px;
        }
      }

      @media only screen and (max-width: 1240px) and (min-width: 950px) {
        .listing-heading {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .listing-text {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;

        @include media(tablet) {
          width: 325px;
        }

        @include media(mobile) {
          width: 100%;
          font-size: 16px;
        }
      }
    }

    .listing-button-container {
      margin-top: 24px;
      width: 250px;

      .listing-button {
        font-family: 'Poppins', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: none;
      }
    }

    .listing-preview {
      width: 682px;
      height: 642px;
      background-image: url('/static/img/landing_pages/search-page-listings-desktop.png');
      background-repeat: no-repeat;
      background-size: cover;

      @include media(tablet) {
        background-image: url('/static/img/landing_pages/search-page-listings-tablet-mini.png');
      }

      @include media(mobile) {
        background-image: url('/static/img/landing_pages/search-page-listings-mobile.png');
        width: 100%;
        height: 300px;
        margin-top: 0px;
      }
    }
  }

  .section-buy {
    width: 100%;
    background-color: #195a50;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 55px 0px 90px 100px;

    @include media(tablet) {
      padding: 55px 0px 55px 34px;
    }

    @include media(mobile) {
      padding: 64px 0px 32px 32px;
      flex-direction: column;
    }

    .buy-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      @include media(mobile) {
        margin-right: 32px;
        height: auto;
      }

      .buy-category {
        color: #463264;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
      }

      .buy-heading {
        color: #ffd750;
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        margin: 16px 0px 24px 0px;

        @include media(tablet) {
          font-size: 32px;
          line-height: 40px;
        }

        @include media(mobile) {
          font-size: 32px;
          line-height: 40px;
        }
      }

      @media only screen and (max-width: 1240px) and (min-width: 950px) {
        .buy-heading {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .buy-text {
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        width: 400px;

        @include media(tablet) {
          width: 350px;
        }

        @include media(mobile) {
          width: 100%;
          font-size: 16px;
        }
      }

      .buy-button-container {
        margin-top: 24px;
        width: 250px;

        @include media(tablet) {
          width: 150px;
        }

        @include media(mobile) {
          width: 140px;
        }

        .buy-button {
          font-family: 'Poppins', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          text-transform: none;

          @include media(tablet) {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }

          @include media(mobile) {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
    }

    .buy-preview {
      width: 710px;
      height: 710px;
      background-image: url('/static/img/landing_pages/looking-to-buy-a-home-desktop.png');
      background-repeat: no-repeat;
      background-size: 100%;

      @include media(tablet) {
        background-image: url('/static/img/landing_pages/looking-to-buy-a-home-tablet.png');
        width: 540px;
        height: 435px;
      }

      @include media(mobile) {
        margin-top: 0px;
        background-image: url('/static/img/landing_pages/looking-to-buy-a-home-mobile.png');
        width: 100%;
        height: 420px;
      }
    }
  }

  .section-personalize {
    width: 100%;
    padding: 60px 100px 125px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media(tablet) {
      padding: 40px 32px 80px 0px;
    }

    @include media(mobile) {
      flex-direction: column;
      padding: 40px 32px 0px 0px;
    }

    .search-home-text-container {
      max-width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media(mobile) {
        order: -1;
        margin-left: 32px;
        max-width: 100%;
      }

      .personalize-category {
        color: #463264;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
      }

      .personalize-heading {
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        margin: 16px 0px 24px 0px;

        @include media(tablet) {
          font-size: 32px;
          line-height: 40px;
        }

        @include media(mobile) {
          font-size: 32px;
          line-height: 40px;
        }
      }

      @media only screen and (max-width: 1240px) and (min-width: 950px) {
        .personalize-heading {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .personalize-text {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;

        @include media(tablet) {
          font-size: 16px;
          width: 320px;
        }

        @include media(mobile) {
          font-size: 16px;
          width: 320px;
        }

        &:nth-of-type(2) {
          margin-top: 24px;
        }
      }
    }

    .personalize-preview {
      width: 650px;
      height: 500px;
      background-image: url('/static/img/landing_pages/search-page-personalize-desktop.png');
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 32px;

      @include media(tablet) {
        background-image: url('/static/img/landing_pages/search-page-personalize-tablet-mini.png');
        margin-right: 32px;
        width: 650px;
        height: 560px;
      }

      @include media(mobile) {
        margin-top: 0px;
        width: 100%;
        height: 430px;
        background-image: url('/static/img/landing_pages/search-page-personalize-mobile.png');
      }
    }

    @media only screen and (max-width: 1240px) and (min-width: 950px) {
      .personalize-preview {
        background-image: url('/static/img/landing_pages/search-page-personalize-tablet-mini.png');
        margin-right: 32px;
        width: 100%;
        height: 650px;
      }
    }
  }

  .indexed-landing-page-banner {
    padding-top: 70px;

    @include media(tablet) {
      padding: 50px 35px 20px 10px;
      height: 420px;
    }

    @include media(mobile) {
      padding-top: 32px;
    }

    @media only screen and (min-width: 950px) and (max-width: 1300px) {
      padding-left: 80px;
    }
  }

  .buy-hero-banner {
    flex-wrap: wrap;
    flex-direction: column;

    .buy-text-container {
      flex: 1;
      padding-right: 40px;
    }

    .buy-hero-image {
      flex: 1;
      text-align: right;
    }
  }

  .join-hero-banner,
  .buy-hero-banner {
    background-color: #463264;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0px 0px 100px;

    @media only screen and (min-width: 950px) and (max-width: 1042px) {
      padding: 40px 0px 0px 32px;
    }

    @include media(tablet) {
      flex-direction: column;
      align-items: flex-start;
      padding: 40px 0px 0px 32px;
    }

    @include media(mobile) {
      flex-direction: column;
      align-items: flex-start;
      padding: 40px 0px 0px 16px;
    }

    .buy-container-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0px;

      @include media(mobile) {
        flex-direction: column;
      }
    }

    .join-text-container,
    .buy-text-container {
      .home-hero-heading-text {
        color: #ffffff;
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 16px;

        @media only screen and (min-width: 950px) and (max-width: 1042px) {
          font-size: 32px;
          line-height: 40px;
        }

        @include media(tablet) {
          font-size: 32px;
          line-height: 40px;
        }

        @include media(mobile) {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .home-hero-text {
        color: #ffffff;
        display: block;
        text-align: left;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 750px;
      }

      .hero-button-container {
        display: flex;
        margin: 40px 0px;

        @include media(mobile) {
          flex-direction: column;
          align-items: center;
        }

        .apply-to-torii-button {
          color: #ffffff;
          margin-right: 30px;
          width: 260px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;

          @include media(mobile) {
            margin: 15px 0px;
          }
        }

        .schedule-button {
          margin-right: 30px;
          width: 260px;
          background-color: #fff;
          color: #ff7350;
          border: 1px solid #ff7350;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;

          @include media(mobile) {
            margin: 5px 0px;
          }
        }
      }
    }

    .join-hero-image,
    .buy-hero-image {
      @media only screen and (min-width: 769px) and (max-width: 1300px) {
        width: 100%;

        .home-hero-image-two {
          width: 100%;
        }
      }

      @include media(tablet) {
        width: 100%;

        .home-hero-image-two {
          width: 100%;
        }
      }

      @include media(mobile) {
        .home-hero-image-two {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .buy-hero-banner {
    .hero-location {
      display: flex;
      align-items: center;

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;

        @include media(mobile) {
          margin-top: 8px;
          text-align: left;
        }
      }

      @include media(tablet) {
        margin-top: 24px;
      }

      @include media(mobile) {
        margin-top: 24px;
      }
    }

    .press-container {
      display: flex;
      margin: 0px 0 30px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      flex: 1;

      @include media(mobile) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0px auto 30px;
      }

      .press-icon {
        margin: 0 45px;

        @media only screen and (max-width: 768px) {
          margin: 0px 15px;
        }

        @include media(mobile) {
          margin: 20px;
        }

        @include media(tablet) {
          margin: 20px;
        }
      }
    }
  }

  .join-header {
    width: 100%;
    height: auto;
    padding: 180px 120px;
    padding-bottom: 200px;
    background-color: #463264;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: clip;
    position: relative;

    @media only screen and (min-width: 950px) and (max-width: 1042px) {
      padding: 60px 100px;
    }

    @include media(tablet) {
      padding: 60px 80px;
    }

    @include media(mobile) {
      padding: 48px 16px;
    }

    .home-hero {
      width: 580px;
      max-width: 604px;

      @include media(tablet) {
        width: 604px;
      }

      @include media(mobile) {
        width: 100%;
      }

      h2 {
        font-size: 36px;
        font-weight: 700;
        line-height: 43px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 16px;
      }

      .home-hero-text {
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #ffffff;

        .home-hero-text-bold {
          display: block;
          font-weight: 700;
        }
      }

      .select-container {
        width: 100%;
        max-width: 568px;
        height: 56px;
        margin: 32px auto;
        background-color: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .select-label {
          font-size: 14px;
          z-index: 10;
          transform: translate(5px, 7.5px) scale(1);
        }

        .text-field {
          width: 530px;

          @include media(mobile) {
            width: 325px;
          }
        }

        .MuiSelect-select {
          text-align: left;
          font-size: 18px;
          line-height: 21.6px;
        }

        .menu-item {
          font-size: 18px;
          line-height: 21.6px;
        }
      }

      .home-hero-button {
        width: 370px;
        height: 56px;
        color: #ffffff;
        background-color: #ff7350;
        margin-bottom: 24px;
        border-radius: 30px;

        &:hover {
          background-color: $purple-secondary;
        }

        @include media(mobile) {
          width: 100%;
        }
      }

      .home-hero-image-container-one {
        position: absolute;
        left: -300px;
        top: 25px;
        z-index: 1;

        @media only screen and (min-width: 950px) and (max-width: 1245px) {
          display: none;
        }

        @include media(tablet) {
          display: none;
        }

        @include media(mobile) {
          display: none;
        }

        .home-hero-image-one {
          width: 610px;
          height: 650px;
        }
      }

      .home-hero-image-container-two {
        position: absolute;
        right: -310px;
        top: 0px;
        z-index: 0;

        @media only screen and (min-width: 950px) and (max-width: 1245px) {
          margin-top: 36px;
          position: relative;
          right: 0px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include media(tablet) {
          margin-top: 36px;
          position: relative;
          right: 0px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include media(mobile) {
          margin-top: 36px;
          position: relative;
          right: 0px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .home-hero-image-two {
          width: 610px;
          height: 650px;

          @include media(mobile) {
            width: 100%;
            height: 400px;
          }
        }

        .home-hero-image-container-three {
          width: 610px;
          height: 650px;
          margin-left: 30px;
        }
      }

      .hero-location {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media(mobile) {
          flex-direction: column;
        }

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: center;
          color: #ffffff;

          @include media(mobile) {
            margin-top: 8px;
          }
        }
      }
    }
  }

  .save-slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 80px 70px 80px;
    background-color: #fafafa;

    @media only screen and (max-width: 530px) {
      padding: 50px 16px 70px 16px;
    }

    @include media(mobile) {
      margin-top: 15px;
    }

    .savings-container {
      width: 982px;

      @media only screen and (max-width: 1200px) {
        width: 100%;
      }

      h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 24px;
      }

      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: center;
        margin: 0px;
        margin-bottom: 32px;
      }
    }

    .slider-card {
      width: 982px;
      height: 419px;
      background-color: #ffffff;
      box-shadow: 0px 4px 16px 0px #00000029;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 1200px) {
        width: 100%;
      }

      @media only screen and (max-width: 768px) {
        padding: 32px 0px 32px 0px;
        width: 100%;
        height: 100%;
      }

      h2 {
        color: #323232;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 32px;

        .savings {
          color: #42a5f5;

          @include media(mobile) {
            margin-top: 16px;
          }
        }
      }

      .slider {
        width: 600px;
        margin-top: 95px;

        @media only screen and (max-width: 1024px) {
          width: 425px;
        }

        @media only screen and (max-width: 768px) {
          width: 374px;
        }

        @include media(mobile) {
          width: 195px;
        }
      }

      .savings {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0px;
        margin-top: 32px;
        margin-bottom: 0px;

        .savings-amount {
          display: block;
          color: #42a5f5;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px;
          letter-spacing: 0px;
          margin-top: 8px;
        }
      }

      .slider-label {
        font-size: 12px;
        color: #323232;
        margin: 0 15px;
      }

      .MuiCardContent-root {
        padding: 0px;
        width: 100%;
      }

      .MuiSlider-thumb {
        height: 25px;
        width: 25px;
        margin-top: -10px;
        position: relative;
        pointer-events: none;
      }

      .MuiSlider-track {
        height: 5px !important;
      }

      .MuiSlider-rail {
        height: 5px;
        border-radius: 3.5px;
      }
    }
  }

  .advantage {
    position: relative;
    padding: 64px 128px;

    @include media(tablet) {
      padding: 0px 80px;
    }

    @include media(mobile) {
      padding: 16px;
      padding-top: 32px;
    }

    .press-container {
      display: flex;
      align-items: center;
      justify-content: center;

      @include media(mobile) {
        flex-direction: column;
        height: 212px;
        justify-content: space-between;
      }

      .press-icon {
        margin: 0 45px;

        @media only screen and (max-width: 768px) {
          margin: 0px 15px;
        }

        @include media(mobile) {
          margin: 0px;
        }
      }
    }

    .advantage-container {
      width: 100%;
      margin-top: 40px;

      .complete-home-buying {
        text-align: left;
        width: 1200px;
        margin: 0 auto;

        @media only screen and (max-width: 1350px) {
          width: 100%;
          margin: 0px;
        }

        h3 {
          text-align: left;
          font-size: 24px;
          line-height: 28.8px;
          font-weight: 700;
          color: #323232;
          margin-bottom: 16px;
        }

        p {
          font-size: 18px;
          line-height: 28px;
          margin: 0px;
        }
      }

      .advantage-card-container {
        max-width: 1185px;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        margin-top: 40px;
        justify-content: space-between;

        @include media(tablet) {
          flex-direction: column;
          align-items: center;
        }

        @include media(mobile) {
          flex-direction: column;
          align-items: center;
        }

        .advantage-card {
          width: 355px;
          height: 275px;
          border-radius: 10px;
          box-shadow: 0px 4px 16px 0px #00000029;

          @media only screen and (min-width: 950px) and (max-width: 1025px) {
            width: 225px;
            height: 200px;
          }

          @media only screen and (min-width: 1026px) and (max-width: 1110px) {
            width: 250px;
            height: 200px;
          }

          @media only screen and (min-width: 1111px) and (max-width: 1175px) {
            width: 275px;
            height: 200px;
          }

          @media only screen and (min-width: 1176px) and (max-width: 1250px) {
            width: 300px;
            height: 225px;
          }

          @media only screen and (min-width: 1251px) and (max-width: 1350px) {
            width: 325px;
            height: 250px;
          }

          @include media(tablet) {
            width: 100%;
            height: auto;
            margin-right: 0px;
            margin-bottom: 32px;
          }

          @include media(mobile) {
            width: 100%;
            height: auto;
            margin-right: 0px;
            margin-bottom: 32px;
          }

          .MuiCardContent-root {
            display: flex;
            height: 100%;
            padding: 0px;
          }

          .card-bar {
            width: 24px;
            background-color: #42a5f5;
          }

          .card-content {
            flex: 1;
            padding: 24px;
            text-align: left;

            @media only screen and (min-width: 950px) and (max-width: 1350px) {
              padding: 12px;
            }

            @include media(tablet) {
              display: flex;
            }

            .advantage-icon {
              margin: 12px auto;

              @media only screen and (min-width: 950px) and (max-width: 1350px) {
                margin: 0px;
              }

              @include media(tablet) {
                margin-right: 50px;
              }
            }

            h3 {
              color: #000000;
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 8px;

              @media only screen and (min-width: 950px) and (max-width: 1110px) {
                font-size: 16px;
              }

              @media only screen and (min-width: 1111px) and (max-width: 1175px) {
                font-size: 18px;
              }

              @media only screen and (min-width: 1176px) and (max-width: 1250px) {
                font-size: 20px;
              }

              @media only screen and (min-width: 1251px) and (max-width: 1350px) {
                font-size: 22px;
              }
            }

            p {
              color: #393939;
              font-size: 16px;
              font-weight: normal;
              line-height: 24px;

              @media only screen and (min-width: 950px) and (max-width: 1110px) {
                font-size: 12px;
                line-height: 12px;
              }

              @media only screen and (min-width: 1111px) and (max-width: 1175px) {
                font-size: 13px;
                line-height: 15px;
              }

              @media only screen and (min-width: 1176px) and (max-width: 1250px) {
                font-size: 14px;
                line-height: 18px;
              }

              @media only screen and (min-width: 1251px) and (max-width: 1350px) {
                font-size: 15px;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }

  .home-advantage {
    padding-top: 40px;
    padding-bottom: 100px;

    @media only screen and(max-width: 1200px) {
      padding: 32px 80px;
    }

    @include media(mobile) {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 32px;
    }
  }

  .advantage-home-container {
    display: flex;
    padding: 0px;
    max-width: 1184px;
    margin: 56px auto 0px auto;

    @media only screen and (min-width: 950px) and (max-width: 1050px) {
      flex-direction: column;
    }

    @include media(tablet) {
      flex-direction: column;
    }

    @include media(mobile) {
      flex-direction: column;
    }

    .home-hero-image-container {
      .home-hero-image {
        width: 479px;
        height: 363px;
        background-image: url('../../../public/static/img/team2.jpg');
        background-size: cover;

        @media only screen and (min-width: 950px) and (max-width: 1050px) {
          margin: 0px auto;
        }

        @include media(tablet) {
          margin: 0px auto;
        }

        @include media(mobile) {
          width: 100%;
        }
      }
    }

    .home-hero-image-container-three {
      top: 0px;
      z-index: 1;
      margin-left: 20px;
      margin-top: -50px;

      @media only screen and (min-width: 950px) and (max-width: 1050px) {
        margin-top: 36px;
        position: relative;
        right: 0px;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @include media(tablet) {
        margin-top: 36px;
        position: relative;
        right: 0px;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @include media(mobile) {
        margin-top: 36px;
        position: relative;
        right: 0px;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .home-hero-image-two {
        width: 610px;
        height: 650px;

        @include media(mobile) {
          width: 100%;
          height: 400px;
        }
      }
    }

    .advantage-header {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @include media(tablet) {
        width: 100%;
        margin-bottom: 45px;
        justify-content: flex-start;
      }

      @include media(mobile) {
        width: 100%;
        margin-top: 22.5px;
        margin-bottom: 45px;
        justify-content: flex-start;
      }

      .advantage-heading {
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: center;

        @include media(mobile) {
          font-size: 32px;
          line-height: 40px;
          letter-spacing: 0em;
        }

        .advantage-headingText {
          font-family: Lato;
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 25px;
          text-align: left;
          margin-bottom: 14px;

          @include media(mobile) {
            font-size: 25px;
            text-align: center;
            margin-bottom: 10px;
            margin-top: -50px;
          }

          @include media(tablet) {
            font-size: 25px;
            text-align: center;
            margin-bottom: 10px;
            margin-top: -50px;
          }
        }

        .advantage-mainText {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 21px;
          line-height: 25px;
          text-align: left;
          margin-bottom: 20px;

          @include media(mobile) {
            font-size: 20px;
            text-align: center;
          }

          @include media(tablet) {
            font-size: 25px;
            text-align: center;
          }
        }

        .advantage-subText {
          font-family: Lato;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 25px;
          text-align: justify;

          @include media(mobile) {
            font-size: 25px;
            text-align: center;
          }

          @include media(tablet) {
            font-size: 25px;
            text-align: center;
          }
        }
      }
    }

    .advantage-header-sell-page {
      justify-content: center;
    }

    .agent-header {
      width: 50%;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @include media(tablet) {
        width: 100%;
        margin-bottom: 45px;
        justify-content: flex-start;
      }

      @include media(mobile) {
        width: 100%;
        margin-top: 22.5px;
        margin-bottom: 45px;
        justify-content: flex-start;
      }

      .agent-heading {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0px;

        @include media(mobile) {
          font-size: 29px;
        }

        .agent-headingText {
          font-family: Lato;
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 25px;
          text-align: left;
          margin-bottom: 14px;

          @include media(mobile) {
            text-align: center;
            margin-bottom: 10px;
            margin-top: -50px;
          }

          @include media(tablet) {
            text-align: center;
            margin-bottom: 10px;
            margin-top: -50px;
          }
        }

        .agent-mainText {
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: 0px;
          text-align: left;
          margin-top: -200px;
          margin-bottom: 50px;
          width: 100%;

          @include media(tablet) {
            font-size: 36px;
            text-align: center;
          }

          @include media(mobile) {
            font-size: 20px;
            text-align: center;
          }
        }

        .agent-subText {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 25px;
          text-align: justify;
          width: 100%;

          @include media(mobile) {
            text-align: center;
          }

          @include media(tablet) {
            text-align: center;
          }
        }
      }
    }

    .advantage-points {
      width: 100%;
      display: flex;
      margin-top: 80px;

      @include media(tablet) {
        width: 100%;
        margin-top: 0px;
        flex-direction: column;
      }

      @include media(mobile) {
        width: 100%;
        margin-top: 0px;
        flex-direction: column;
      }

      .advantage-point-container {
        width: 392px;
        //height: 476px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        border-radius: 32px;
        margin: 0px 16px;

        @include media(tablet) {
          width: 100%;
          height: 352px;
          margin: 16px auto;
        }

        @include media(mobile) {
          width: 100%;
          height: 468px;
          margin: 16px auto;
        }

        .advantage-point-heading {
          font-family: 'Poppins', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          min-height: 64px;
          margin-bottom: 24px;

          @include media(tablet) {
            min-height: auto;
          }

          @include media(mobile) {
            min-height: auto;
          }
        }

        .advantage-point-text {
          color: #ffffff;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0px;
          text-align: left;
        }
      }
    }

    .blue-blob {
      position: relative;
      height: 875px;
      width: 235px;
      background-position: left;
      background-repeat: no-repeat;
      background-size: cover;
      right: 32%;
      top: 1400px;
      background-image: url('/static/img/landing_pages/sell-page-blue-blob.png');
      margin-top: -725px;

      @media only screen and (min-width: 1251px) and (max-width: 1600px) {
        right: 22%;
      }

      @media only screen and (max-width: 1250px) {
        right: 12%;
      }
    }

    .yellow-blob {
      position: relative;
      height: 370px;
      width: 150px;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      left: 120%;
      top: 465px;
      margin-top: -430px;
      background-image: url('/static/img/landing_pages/sell-page-yellow-blob.png');

      @media only screen and (min-width: 1251px) and (max-width: 1740px) {
        left: 110%;
      }

      @media only screen and (max-width: 1500px) {
        left: 100%;
      }
    }

    .pink-blob {
      position: relative;
      height: 890px;
      width: 275px;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      left: 110%;
      top: 1700px;
      margin-top: -890px;
      background-image: url('/static/img/landing_pages/sell-page-pink-blob.png');

      @media only screen and (min-width: 1600px) and (max-width: 1750px) {
        left: 100%;
      }

      @media only screen and (max-width: 1599px) {
        left: 90%;
      }
    }
  }

  .choose-your-path {
    position: relative;
    padding: 64px 128px;
    background-color: #faf5f0;
    font-family: 'Sharp Grotesk Semibold', sans-serif;

    @include media(tablet) {
      padding: 0px 50px;
      margin: 80px 0px 40px 0px;
    }

    @include media(mobile) {
      padding: 16px;
      padding-top: 90px;
    }

    .choose-your-path-header {
      font-size: 32px;
    }

    .path-choices {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin: 40px 0px;

      @include media(mobile) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 3%;
      }

      .buy-button {
        background: url('/static/img/landing_pages/buy-button.svg') no-repeat;
        width: 210px;
        height: 175px;
        transition-property: transform;
        transition-duration: 0.3s;

        &:hover {
          transform: scale(1.15);
        }

        @include media(tablet) {
          background: url('/static/img/landing_pages/buy-button-tablet.svg') no-repeat;
          width: 160px;
          height: 135px;
        }

        @include media(mobile) {
          background: url('/static/img/landing_pages/buy-button-mobile.svg') no-repeat;
          width: 205px;
          height: 173px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1110px) {
          background: url('/static/img/landing_pages/buy-button-tablet.svg') no-repeat;
          width: 220px;
        }
      }

      .sell-button {
        background: url('/static/img/landing_pages/sell-button.svg') no-repeat;
        width: 215px;
        height: 190px;
        transition-property: transform;
        transition-duration: 0.3s;

        &:hover {
          transform: scale(1.15);
        }

        @include media(tablet) {
          background: url('/static/img/landing_pages/sell-button-tablet.svg') no-repeat;
          width: 150px;
          height: 135px;
        }

        @include media(mobile) {
          background: url('/static/img/landing_pages/sell-button-mobile.svg') no-repeat;
          width: 200px;
          height: 175px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1110px) {
          background: url('/static/img/landing_pages/sell-button-tablet.svg') no-repeat;
          width: 220px;
        }
      }

      .search-button {
        background: url('/static/img/landing_pages/search-button.svg') no-repeat;
        width: 210px;
        height: 190px;
        transition-property: transform;
        transition-duration: 0.3s;

        &:hover {
          transform: scale(1.15);
        }

        @include media(tablet) {
          background: url('/static/img/landing_pages/search-button-tablet.svg') no-repeat;
          width: 150px;
          height: 135px;
        }

        @include media(mobile) {
          background: url('/static/img/landing_pages/search-button-mobile.svg') no-repeat;
          width: 203px;
          height: 186px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1110px) {
          background: url('/static/img/landing_pages/search-button-tablet.svg') no-repeat;
          width: 220px;
        }
      }

      .agents-button {
        background: url('/static/img/landing_pages/agents-button.svg') no-repeat;
        width: 230px;
        height: 190px;
        transition-property: transform;
        transition-duration: 0.3s;

        &:hover {
          transform: scale(1.15);
        }

        @include media(tablet) {
          background: url('/static/img/landing_pages/agents-button-tablet.svg') no-repeat;
          width: 170px;
          height: 135px;
        }

        @include media(mobile) {
          background: url('/static/img/landing_pages/agents-button-mobile.svg') no-repeat;
          width: 230px;
          height: 180px;
        }

        @media only screen and (min-width: 950px) and (max-width: 1110px) {
          background: url('/static/img/landing_pages/agents-button-tablet.svg') no-repeat;
          width: 250px;
        }
      }

      .path-button-mobile {
        @include media(mobile) {
          margin: 10px 0px;
        }

        @include media(tablet) {
          margin: 0px 5px;
        }
      }
    }
  }

  .home-path-details {
    position: relative;
    padding: 64px 100px;
    background-color: #faf5f0;
    font-family: 'Sharp Grotesk Semibold', sans-serif;

    @include media(tablet) {
      padding: 0px 10px;
    }

    @include media(mobile) {
      padding: 0px 10px;
    }

    @media only screen and (min-width: 950px) and (max-width: 1430px) {
      padding: 64px 0px;
    }

    .detail-section {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin: 100px 0px;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }

      .detail-section-image-container {
        flex: 1;

        @media only screen and (max-width: 600px) {
          margin-bottom: 20px;
        }

        img {
          max-width: 100%;
        }

        .detail-section-image-desktop {
          @include media(tablet) {
            display: none;
          }

          @include media(mobile) {
            display: none;
          }
        }

        .detail-section-image-tablet {
          display: none;

          @include media(tablet) {
            display: block;
            margin: 0px auto;
          }
        }

        .detail-section-image-mobile {
          display: none;

          @include media(mobile) {
            display: block;
          }
        }

        &.mobile {
          flex: none;
        }
      }

      .selling-section {
        @media only screen and (max-width: 600px) {
          order: -1;
        }
      }

      /*
      .image-left-align {
        position: relative;
        right: 20%;
      }
      .image-right-align {
        position: relative;
        left: 20%;
      }*/
      .image-left-align {
        position: relative;
        right: inherit;
        left: -9%;
        text-align: left;
      }

      .image-right-align {
        position: relative;
        left: inherit;
        right: -9%;
        text-align: right;
      }

      .detail-section-text {
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: left;
        font-family: Poppins, sans-serif;
        //min-width: 600px;
        padding: 0px 24px;

        br {
          @include media(tablet) {
            content: ' ';
          }

          @include media(mobile) {
            content: ' ';
          }

          @media only screen and (min-width: 950px) and (max-width: 1240px) {
            content: ' ';
          }
        }

        @include media(tablet) {
          margin: 0px 30px;
          min-width: initial;
        }

        @media only screen and (max-width: 600px) {
          margin-left: 0px;
          margin-right: 0px;
        }

        @include media(mobile) {
          min-width: initial;
        }

        .detail-section-mini-heading {
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          padding: 10px 0px;
        }

        .detail-section-main-heading {
          font-family: 'Sharp Grotesk Semibold', sans-serif;
          font-size: 48px;
          line-height: 56px;
          color: black;
          padding: 10px 0px;

          @include media(tablet) {
            font-size: 24px;
            line-height: 32px;
          }

          @include media(mobile) {
            font-size: 24px;
            line-height: 32px;
          }
        }

        .detail-section-sub-heading {
          font-size: 20px;
          font-weight: 400;
          line-height: 32px;
          padding: 10px 0px;

          @include media(tablet) {
            font-size: 18px;
            line-height: 32px;
          }

          @include media(mobile) {
            font-size: 16px;
            line-height: 32px;
          }
        }

        .detail-section-learn-more-button {
          width: 245px;
          height: 65px;
          border-radius: 8px;
          padding: 16px 24px;
          text-transform: none;
          margin: 10px 0px;
          font-size: 24px;
          color: #fff;
          font-weight: bold;

          @include media(tablet) {
            width: 180px;
            height: 50px;
            padding: 8px 24px;
            font-size: 16px;
          }

          @media only screen and (max-width: 600px) {
            width: 100%;
            height: 50px;
            padding: 8px 24px;
            font-size: 16px;
            margin-bottom: 64px;
          }
        }
      }

      .detail-section-text-tablet {
        @include media(tablet) {
          margin: 0px;
        }
      }
    }

    &.full-width {
      padding-left: 0px;
      padding-right: 0px;

      .detail-section {
        margin: 100px 0px;

        .text-left-align {
          padding-left: 100px;
        }

        .text-right-align {
          padding-right: 100px;
        }

        .image-left-align {
          position: relative;
          right: inherit;
          left: -2%;
          text-align: left;
        }

        .image-right-align {
          position: relative;
          left: inherit;
          right: -2%;
          text-align: right;
        }
      }
    }
  }

  .get-started-button {
    margin-top: 65px;
    display: block;
  }

  .explainer-video-container {
    padding: 80px 120px;

    @include media(tablet) {
      padding: 80px;
    }

    @include media(mobile) {
      padding: 80px 16px;
    }

    h2 {
      margin-bottom: 40px;
    }

    .buying-with-torii {
      height: auto;
      width: auto;
      color: #282828;
      font-size: 36px;
      font-weight: 900;
      line-height: 44px;
      text-align: center;
      margin: 0 0 25px 0;

      @include media(mobile) {
        height: 88px;
        width: 375px;
        margin: 0 auto 25px;
      }
    }

    .explainer-video {
      height: 440px;
      width: 782px;

      @include media(tablet) {
        height: 342px;
        width: 100%;
      }

      @include media(mobile) {
        height: 215px;
        width: 100%;
      }
    }

    .faq {
      width: 782px;
      margin: 40px auto 0px auto;

      @include media(tablet) {
        width: 100%;
      }

      @include media(mobile) {
        width: 100%;
      }
    }

    h3 {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: center;
    }

    p {
      color: $text-color-light;
      font-size: 22px;
      line-height: 35px;
      margin: 0;
      text-align: left;

      @include media(mobile) {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .learn-more {
    margin-top: 50px;

    a {
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.5px;
      text-align: center;
      text-decoration: none;
    }
  }

  .torii-team {
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    max-width: 1000px;

    .slider {
      @include media(mobile) {
        width: 215px !important;
      }
    }

    .slider-frame {
      @include media(mobile) {
        width: 215px !important;
      }
    }

    .slider-list {
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;

      :focus {
        outline: none !important;
      }
    }

    .slider-slide {
      @include media(mobile) {
        width: 215px !important;
      }

      :focus {
        outline: none !important;
      }
    }

    .team-title {
      color: #323232;
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 44px;
      text-align: center;

      @include media(mobile) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    .team-subtitle {
      color: #323232;
      font-size: 18px;
      font-weight: initial;
      letter-spacing: 0;
      line-height: 28px;
      text-align: center;
      margin: 12px auto 0;

      @include media(mobile) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .team-slider {
      margin-top: 45px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 300px;

      @include media(tablet) {
        width: 430px;
        margin: 45px auto 0;
      }

      @include media(mobile) {
        width: 200px;
        margin: 45px auto 0;
      }

      .pagingDotsContainer {
        @include media(mobile) {
          top: 10px !important;
        }
      }

      .team-member {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 200px;
        flex-direction: column;
        margin: 0 auto;
      }

      .team-member-name {
        color: #323232;
        font-size: 18px;
        font-weight: bold;
        margin-top: 15px;
        letter-spacing: 0;
        line-height: 30px;
        text-align: center;
      }

      .team-member-info {
        color: #323232;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
      }

      .team-member-photo {
        height: 150px;
        width: 150px;
        border-radius: 75px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        &.jason-carter {
          background-image: url('/static/img/about/jason.png');
        }

        &.stephen-agar {
          background-image: url('/static/img/about/stephen.png');
        }

        &.saad-munir {
          background-image: url('/static/img/about/saad.png');
        }

        &.jennifer-mason {
          background-image: url('/static/img/about/jennifer.png');
        }
      }

      .team-member-photo-link {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 100%;
        width: 100%;
      }
    }
  }

  .testimonials {
    background: #faf5f0;
    padding: 72px 0;
    position: relative;
    overflow: hidden;

    @include media(tablet) {
      padding: 40px 0;
    }

    @include media(mobile) {
      padding: 40px 0;
    }

    .subtitle {
      font-size: 64px;
      text-align: left;
      padding-right: 20vw;
      font-weight: 600;
      font-family: 'Sharp Grotesk Semibold', sans-serif;

      @include media(tablet) {
        font-size: 48px;
      }

      @include media(mobile) {
        font-size: 32px;
        text-align: center;
        padding: 0px;
      }
    }

    .navigationButtonContainer {
      @include media(tablet) {
        margin: 20px 0px;
      }

      @include media(mobile) {
        margin: 20px 0px;
      }

      .navigationButton {
        border: none;
        cursor: pointer;
        background: #faf5f0;
        padding: 0px;

        &:nth-of-type(1) {
          margin-right: 15px;
        }
      }
    }

    .blue-blob {
      position: absolute;
      height: 155px;
      width: 155px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      left: 15%;
      top: 45%;
      background-image: url('/static/img/small_blue_blob.svg');

      @include media(mobile) {
        left: -10%;
      }
    }

    .yellow-blob {
      position: absolute;
      height: 182px;
      width: 182px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      right: 35%;
      top: 25%;
      background-image: url('/static/img/small_yellow_blob.svg');

      @include media(tablet) {
        right: 10%;
        top: 15%;
      }

      @include media(mobile) {
        right: -10%;
      }
    }

    .title-box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 0 100px;
      margin-bottom: 50px;

      @include media(tablet) {
        padding: 0 50px;
      }

      @include media(mobile) {
        padding: 0 10px;
        justify-content: center;
      }

      h2 {
        color: #2d2332;
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 64px;
        font-weight: 600;
        margin: 0;

        @include media(tablet) {
          font-size: 48px;
        }

        @include media(mobile) {
          font-size: 32px;
        }
      }
    }

    .photo-nav-button {
      height: 64px;
      border: 1px solid $purple-primary;
      border-radius: 15px;
      width: 40px;
      margin: 0 15px;
    }

    .start-search-button {
      background-color: #ff7350;
      height: 60px;
      border-radius: 24px;
      padding: 16px 24px;
      text-transform: none;
      color: #ffffff;
      font-weight: bold;
      font-size: 18px;
      margin-top: 45px;
    }

    .slider {
      .slider-frame {
        @include media(mobile) {
          width: 100% !important;
        }
      }
    }

    ul {
      padding: 0;
      margin: 20px auto 0;

      @include media(tablet) {
        max-width: 690px;
        margin: 0 auto;
      }

      @include media(mobile) {
        max-width: 90%;
        margin: 0 auto;
      }

      .testimonial-item {
        margin: 0 auto;
        position: absolute;
        bottom: 0;

        @include media(mobile) {
          position: initial;
        }

        .review-box {
          max-width: 320px;
          border-radius: 20px;
          background-color: $purple-primary;
          padding: 10px 15px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;

          @include media(mobile) {
            width: 320px;
          }
        }

        .quote-avatar {
          height: 90px;
          width: 90px;
          border-radius: 50px;
          margin-top: 25px;
        }

        .quote {
          color: #faf5f0;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          margin-top: 20px;
        }

        .info {
          font-size: 14px;
          color: #faf5f0;
          font-weight: bold;
          margin-top: 15px;
          height: 20px;
          text-align: left;

          @include media(mobile) {
            margin-top: 0;
          }
        }
      }
    }
  }

  .sell-learn-more-section {
    background: #195a50;
    width: 100%;
    height: auto;
    padding: 177px 143px 187px 193px;

    @include media(mobile) {
      padding: 40px;
    }

    .learn-more-container {
      .heading-text {
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-weight: 600;
        font-size: 52px;
        line-height: 64px;
        text-align: initial;
        color: #ffd750;

        @include media(mobile) {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .details {
        text-align: justify;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        color: #ffffff;

        @include media(mobile) {
          font-size: 16px;
        }
      }
    }

    .sell-learnmore-image {
      position: relative;
      height: 130%;
      background-image: url('/static/img/landing_pages/sell-learnMore-desktop.png');
      background-position: right;
      background-repeat: no-repeat;
      bottom: 120%;
      left: 11%;

      @media only screen and (min-width: 1300px) and (max-width: 1490px) {
        left: 20%;
      }

      @media only screen and (min-width: 769px) and (max-width: 1300px) {
        background-image: url('/static/img/landing_pages/sell-learnMore-tablet.png');
        margin-top: 240px;
        padding: 33px;
        bottom: 70%;
        left: 30%;
      }

      @include media(tablet) {
        background-image: url('/static/img/landing_pages/sell-learnMore-tablet.png');
        margin-top: 240px;
        padding: 33px;
        bottom: 50%;
        left: 15%;
        height: 100%;
        width: 120%;
      }

      @include media(mobile) {
        background-image: url('/static/img/landing_pages/sell-learnMore-mobile.png');
        margin-top: 200px;
        padding: 75px;
        bottom: 50%;
        left: 0px;
        height: 100%;
        width: 140%;
      }
    }
  }

  .home-review-button {
    width: 332px;
    height: 56px;
    color: #ffffff;
    background-color: rgba(66, 165, 245, 1);
    margin-bottom: 24px;

    &:hover {
      background-color: #3d86ef;
    }

    @include media(mobile) {
      width: 100%;
    }
  }

  .more-reviews {
    margin-top: 72px;

    a {
      text-align: center;
      text-decoration: none;
    }
  }

  .design {
    background: #faf5f0;
    height: 650px;
    padding: 0px 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media(tablet) {
      height: auto;
      padding: 16px 80px;
      flex-direction: column;
    }

    @include media(mobile) {
      padding: 16px;
      padding-top: 32px;
      height: auto;
      flex-direction: column;
    }

    .design-preview {
      width: 50%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('/static/img/home/new-phone.png');

      @include media(tablet) {
        width: 275%;
        height: 900px;
        margin-top: 40px;
      }

      @include media(mobile) {
        width: 95%;
        height: 882px;
        margin-top: 40px;
      }

      .phone-preview {
        z-index: 1;
      }
    }

    .design-info {
      width: 50%;
      text-align: left;

      @include media(tablet) {
        width: 100%;
        padding-right: 0px;
      }

      @include media(mobile) {
        width: 100%;
        padding-right: 0px;
      }

      span {
        display: inline-block;
        color: #42a5f5;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
      }

      h3 {
        color: #323232;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        margin-bottom: 24px;
        letter-spacing: 0px;
      }

      p {
        color: #555555;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 32px;
        letter-spacing: 0px;
      }

      .design-info-heading {
        margin-bottom: 8px;
      }

      .app-buttons-container {
        width: 100%;
        z-index: 1;
        padding: 0px;
        display: flex;
        justify-content: flex-start;

        @include media(mobile) {
          flex-direction: column;
        }

        .ios-button {
          height: 64px;
          padding: 10px 20px;
          border-radius: 5px;
          background-color: #195a50;
          margin-right: 16px;

          @include media(tablet) {
            width: 47.5%;
          }

          @include media(mobile) {
            width: 100%;
            margin-bottom: 16px;
          }

          .get-started-button {
            text-decoration: none;
            margin: 0px;
            display: flex;
            justify-content: center;
            align-items: center;

            .button-tag {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 20px;

              @media only screen and (min-width: 950px) and (max-width: 1075px) {
                margin-left: 5px;
              }

              @include media(tablet) {
                width: 47.5%;
              }

              .button-cta {
                display: block;
                text-transform: none;
                color: #ffffff;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
              }

              .button-title {
                color: #ffffff;
                text-transform: capitalize;
                font-size: 18px;
                font-weight: 700;
                line-height: 22px;
                letter-spacing: 0px;
              }
            }
          }
        }

        .android-button {
          height: 64px;
          padding: 0px;
          border-radius: 5px;
          background-color: #195a50;
          padding: 10px 20px;

          @include media(tablet) {
            width: 47.5%;
          }

          @include media(mobile) {
            width: 100%;
          }

          .get-started-button {
            text-decoration: none;
            margin: 0px;
            display: flex;
            justify-content: center;
            align-items: center;

            .button-tag {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 20px;

              @media only screen and (min-width: 950px) and (max-width: 1075px) {
                margin-left: 5px;
              }

              .button-cta {
                display: block;
                text-transform: none;
                color: #ffffff;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
              }

              .button-title {
                color: #ffffff;
                text-transform: capitalize;
                font-size: 18px;
                font-weight: 700;
                line-height: 22px;
                letter-spacing: 0px;
              }
            }
          }
        }
      }
    }
  }

  .home-design {
    height: 750px;
    padding: 36px 120px;
    overflow: hidden;

    @media only screen and(max-width: 1200px) {
      padding: 16px 32px;
    }

    @include media(tablet) {
      padding: 16px 32px;
      padding-top: 80px;
      height: 1300px;
    }

    @include media(mobile) {
      padding: 32px 16px;
      height: 1150px;
    }

    .home-design-info {
      @include media(tablet) {
        margin-top: 16px;
      }

      @include media(mobile) {
        margin-top: 16px;
      }

      h1 {
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: 72px;
        letter-spacing: 0em;
        text-align: left;

        @include media(mobile) {
          font-size: 40px;
          line-height: 50px;
        }
      }

      .design-text-container {
        margin-top: 32px;

        p {
          color: #2d2332;
          font-family: Poppins, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
        }

        .phone-preview-break-two {
          @media only screen and(max-width: 1040px) and (min-width: 950px) {
            display: none;
          }
        }

        .phone-preview-break-three {
          @media only screen and(max-width: 1040px) and (min-width: 950px) {
            display: none;
          }
        }
      }

      .home-app-buttons-container {
        margin-top: 24px;

        @include media(tablet) {
          justify-content: space-between;
          margin-bottom: 24px;
        }

        @include media(mobile) {
          justify-content: space-between;
        }
      }
    }

    .home-design-preview {
      @include media(tablet) {
        margin-top: 0px;
      }

      @include media(mobile) {
        margin-top: 0px;
      }
    }
  }

  .agentTestimonials {
    padding: 0 32px;
    padding-bottom: 100px;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fafafa;
    width: 100%;

    .textBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fafafa;
      width: 100%;

      .subtitle {
        color: #323232;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 40px;

        @include media(tablet) {
          margin-top: 0px;
          text-align: center;
        }

        @include media(mobile) {
          margin-top: 0px;
          text-align: center;
        }
      }

      .feedbackQuotes {
        color: $purple-primary;
        font-family: 'karla';
        font-weight: 700;
        font-size: 100px;
        line-height: 140px;
      }

      .feedbackContent {
        color: $purple-primary;
        font-family: 'Lato';
        font-weight: 700;
        font-size: 24px;
        line-height: 28.8px;
        text-align: center;
        max-width: 770px;

        @include media(tablet) {
          max-width: 600px;
        }

        @include media(mobile) {
          max-width: 352px;
        }
      }
    }

    .feebackPerson {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .feedbackPersonPicture {
        width: 140px;
        height: 140px;
        margin-bottom: 5px;
      }

      .personTitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  .agentBenefits {
    display: flex;
    justify-content: flex-start;
    padding: 0px;

    .benefits-container {
      background-color: rgba(250, 250, 250, 1);
      width: 100%;
      display: flex;
      justify-content: center;

      @media only screen and (max-width: 1400px) {
        justify-content: flex-start;
      }

      align-items: center;
      padding: 80px 132px;

      @media only screen and(max-width: 1200px) {
        padding: 80px 84px;
      }

      @include media(tablet) {
        margin-right: 0px;
        flex-direction: column;
        padding: 32px 80px;
      }

      @include media(mobile) {
        margin-right: 0px;
        flex-direction: column;
        padding: 32px 16px;
      }

      .benefits-image-box {
        height: 100%;
        display: flex;
        padding-left: 150px;

        @include media(tablet) {
          width: 100%;
          margin-top: 50px;
          justify-content: center;
          padding-left: 0px;
        }

        @include media(mobile) {
          width: 100%;
          margin-top: 30px;
          padding-left: 0px;
        }

        .benefits-image {
          background-image: url('/static/img/team.jpg');
          background-size: cover;
          border-radius: 20px;
          width: 467px;
          height: 420px;

          @include media(tablet) {
            background-image: url('/static/img/team.jpg');
            margin-bottom: 56px;
            max-width: 100%;
          }

          @include media(mobile) {
            background-image: url('/static/img/team.jpg');
            margin-bottom: 56px;
            max-width: 100%;
          }
        }
      }

      .benefits-textbox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 100px;
        padding-top: 150px;
        width: 100%;

        @include media(tablet) {
          width: 100%;
          padding: 0px;
        }

        @include media(mobile) {
          width: 100%;
          padding: 0px;
        }

        .benefits-heading {
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: 0px;
          text-align: left;
          margin-top: -200px;
          margin-bottom: 50px;
          width: 100%;

          @include media(tablet) {
            margin-top: 0px;
            text-align: center;
          }

          @include media(mobile) {
            margin-top: 0px;
            text-align: center;
          }
        }

        .benefit {
          margin-top: 10px;
          margin-bottom: 10px;
          text-align: initial;
          display: flex;
        }
      }
    }
  }

  .agentValues {
    display: flex;
    flex-wrap: wrap;
    margin: 50px auto 0px auto;
    padding: 0px;

    .valuesContainer {
      background-color: #ffffff;
      width: 100%;

      .valuesHeading {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 40px;

        @include media(tablet) {
          margin-top: 0px;
          text-align: center;
        }

        @include media(mobile) {
          margin-top: 0px;
          text-align: center;
        }
      }

      .values {
        display: flex;
        justify-content: space-between;
        align-items: left;
        flex-wrap: wrap;
        max-width: 1375px;
        margin: auto;

        @include media(tablet) {
          max-width: 609px;
        }

        @include media(mobile) {
          max-width: 351px;
        }

        .value {
          padding: 0px 50px 50px 50px;
          width: 375px;
          display: flex;
          flex-direction: column;

          @include media(tablet) {
            width: 100%;
            text-align: initial;
          }

          @include media(mobile) {
            width: 100%;
            text-align: initial;
            padding: 0px 0px 20px 10px;
          }

          .textTertiary {
            color: #555555;
            text-align: start;
          }

          .textSecondary {
            color: black;
            font-weight: 700;
            text-align: start;
            margin-top: 5px;
            margin-bottom: 5px;

            @include media(mobile) {
              margin-top: 0px;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }

  .contact {
    background: #fafafa;

    .social-controls {
      margin: 25px 0;

      .social-control-buttons {
        margin: 25px 0;
      }
    }
  }

  .join-index {
    background-color: #faf5f0;
    padding: 150px 100px 75px 100px;

    @include media(tablet) {
      padding: 250px 32px 125px 32px;
    }

    @include media(mobile) {
      padding: 125px 16px 100px 16px;
    }

    .join-index-container {
      max-width: 1240px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @include media(tablet) {
        flex-direction: column;
      }

      @include media(mobile) {
        flex-direction: column;
      }

      .index-heading {
        @include media(tablet) {
          margin-bottom: 65px;
        }

        @include media(mobile) {
          margin-bottom: 65px;
        }

        h2 {
          color: #000000;
          font-family: 'Sharp Grotesk Semibold', sans-serif;
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: 48px;
          letter-spacing: 0em;
          text-align: left;

          @include media(mobile) {
            font-size: 28px;
          }
        }

        .index-subheading-container {
          display: flex;
          align-items: center;

          h4 {
            color: #195a50;
            font-family: 'Sharp Grotesk Semibold', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            margin-right: 10px;

            @include media(mobile) {
              font-size: 20px;
            }
          }
        }
      }

      .index-navigation {
        @include media(tablet) {
          width: 100%;
        }

        @include media(mobile) {
          width: 100%;
        }

        .nav-list {
          margin: 0px;
          padding: 0px;

          @include media(tablet) {
            width: 100%;
          }

          @include media(mobile) {
            width: 100%;
          }

          .nav-item {
            border-radius: 24px;
            cursor: pointer;
            margin: 0px;
            padding: 24px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            min-height: 96px;
            margin-bottom: 24px;
            width: 600px;
            transition-property: transform;
            transition-duration: 0.3s;

            &:hover {
              transform: scale(1.1);
            }

            @include media(tablet) {
              width: 100%;
            }

            @include media(mobile) {
              width: 100%;
            }

            .nav-text {
              margin: 0px;
              font-family: 'Sharp Grotesk Semibold', sans-serif;
              font-size: 40px;
              font-style: normal;
              font-weight: 600;
              line-height: 48px;
              letter-spacing: 0em;
              text-align: left;

              @include media(mobile) {
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
              }
            }
          }
        }
      }
    }
  }

  .torii-way {
    //background-color: #ffffff;
    padding: 75px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media(tablet) {
      padding: 56px 32px;
      padding-bottom: 75px;
    }

    @include media(mobile) {
      padding: 32px 16px;
      padding-bottom: 75px;
    }

    .heading {
      color: #000000;
      font-family: 'Sharp Grotesk Semibold', sans-serif;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px;
      letter-spacing: 0em;
      text-align: center;

      @include media(mobile) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .video-container {
      margin: 55px auto 40px;
      border-radius: 24px;

      @include media(tablet) {
        width: 100%;
      }

      @include media(mobile) {
        margin: 32px auto;
        width: 100%;
      }
    }

    .subtext {
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;

      @include media(mobile) {
        font-size: 18px;
      }
    }
  }

  .leads {
    //background-color: #195a50;
    padding: 0px;

    .leads-container {
      margin: 100px 0px;
      padding-left: 100px;

      @include media(tablet) {
        margin: 80px 0px;
        padding-left: 32px;
      }

      @include media(mobile) {
        margin: 48px 0px;
        padding-left: 16px;
      }

      .photo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        margin-top: 45px;

        @include media(tablet) {
          width: 100%;
          margin: auto;
          margin-top: 50px;
        }

        @include media(mobile) {
          width: 100%;
          margin: auto;
          margin-top: 50px;
        }

        img {
          width: 308px;
          height: 308px;
        }

        .photo-text {
          //color: #ffd750;
          margin: 20px 0px;
          width: 308px;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
        }

        .photo-name {
          //color: #ffd750;
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .leads-text {
          display: flex;
          flex-direction: column;
          width: 50%;
          text-align: left;

          @include media(tablet) {
            width: 100%;
            padding-right: 32px;
            margin-bottom: 64px;
          }

          @include media(mobile) {
            width: 100%;
            padding-right: 16px;
            margin-bottom: 64px;
          }

          .heading {
            color: #463264;
            font-size: 24px;
            margin-bottom: 16px;
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }

          .sub-heading {
            //color: #ffd750;
            margin-bottom: 24px;
            font-family: 'Sharp Grotesk Semibold', sans-serif;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: 0em;
            text-align: left;

            @include media(mobile) {
              font-size: 30px;
              font-weight: 600;
              line-height: 40px;
            }

            br {
              @include media(tablet) {
                content: ' ';
              }

              @include media(mobile) {
                content: ' ';
              }

              @media only screen and (min-width: 950px) and (max-width: 1240px) {
                content: ' ';
              }
            }
          }

          .main-text {
            //color: #ffd750;
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            max-width: 604px;
          }
        }

        .leads-saad {
          width: 50%;
          height: auto;

          @include media(tablet) {
            width: 100%;
            order: -1;
          }

          @include media(mobile) {
            width: 100%;
            order: -1;
          }
        }
      }
    }
  }

  .marketing {
    //background-color: #822841;
    padding: 0px;

    .marketing-container {
      max-width: 1184px;
      margin: 100px auto;

      @include media(tablet) {
        margin: 80px 32px 50px 32px;
      }

      @include media(mobile) {
        margin: 48px 16px 48px 16px;
      }

      .photo-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 50px;

        @include media(tablet) {
          width: 100%;
          margin: auto;
          margin-left: 0px;
          align-items: center;
        }

        @include media(mobile) {
          width: 100%;
          margin: auto;
          margin-left: 0px;
          align-items: center;
        }

        img {
          width: 308px;
          height: 308px;
        }

        .photo-text {
          //color: #ffffff;
          margin: 20px 0px;
          width: 308px;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
        }

        .photo-name {
          //color: #ffffff;
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0em;
          margin: 0px auto;
        }
      }

      .row {
        display: flex;
        justify-content: space-between;

        .marketing-text {
          display: flex;
          flex-direction: column;
          width: 50%;
          text-align: left;

          @include media(tablet) {
            order: -1;
            width: 100%;
            margin-bottom: 64px;
          }

          @include media(mobile) {
            order: -1;
            width: 100%;
            margin-bottom: 64px;
          }

          .heading {
            color: #463264;
            font-size: 24px;
            margin-bottom: 16px;
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }

          .sub-heading {
            //color: #ff7350;
            margin-bottom: 24px;
            font-family: 'Sharp Grotesk Semibold', sans-serif;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: 0em;
            text-align: left;

            @include media(mobile) {
              font-size: 32px;
              font-weight: 600;
              line-height: 40px;
            }
          }

          .main-text {
            //color: #faf5f0;
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }
  }

  .perks {
    //background-color: #463264;
    padding: 100px 0px;

    @include media(tablet) {
      padding: 80px 32px;
    }

    @include media(mobile) {
      padding: 48px 16px;
      padding: 0px 16px;
    }

    .perks-container {
      max-width: 1184px;
      margin: 0px auto;
      margin-bottom: 100px;

      .row {
        display: flex;
        justify-content: space-between;
        margin: 50px 0px;

        @include media(tablet) {
          flex-direction: column;
          align-items: center;
        }

        @include media(mobile) {
          flex-direction: column;
        }

        .perks-text {
          display: flex;
          flex-direction: column;
          width: 50%;
          text-align: left;

          @include media(tablet) {
            width: 100%;
            order: -1;
            margin-bottom: 24px;
          }

          @include media(mobile) {
            width: 100%;
            margin-bottom: 48px;
          }

          .perks-list {
            list-style: circle;
            //color: #ffa0be;
          }

          .heading {
            color: #463264;
            font-size: 24px;
            margin-bottom: 16px;
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }

          .sub-heading {
            //color: #ffa0be;
            margin-bottom: 24px;
            font-family: 'Sharp Grotesk Semibold', sans-serif;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: 0em;
            text-align: left;

            @include media(mobile) {
              font-size: 32px;
              font-weight: 600;
              line-height: 40px;
            }
          }

          .main-text {
            //color: #ffa0be;
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }
        }

        .equity-image {
          @include media(tablet) {
            order: 1;
          }

          @include media(mobile) {
            order: 1;
          }
        }

        img {
          width: auto;
          height: 435px;

          @include media(tablet) {
            width: 415px;
            height: auto;
          }

          @include media(mobile) {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .support-training {
    //background-color: #195a50;
    padding: 0px;

    .training-container {
      max-width: 1184px;
      margin: 100px auto;

      @include media(tablet) {
        margin: 80px 32px 35px 32px;
      }

      @include media(mobile) {
        margin: 48px 16px 48px 16px;
      }

      .row {
        display: flex;
        justify-content: space-between;
        margin: 50px 0px;

        @include media(tablet) {
          flex-direction: column;
        }

        @include media(mobile) {
          flex-direction: column;
        }

        .photo-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 50%;

          @include media(tablet) {
            width: 100%;
            margin: auto;
          }

          @include media(mobile) {
            width: 100%;
            margin: auto;
          }

          img {
            width: 308px;
            height: 308px;
          }

          .photo-text {
            //color: #ffd750;
            margin: 20px 0px;
            width: 308px;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
          }

          .photo-name {
            //color: #ffd750;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }
        }

        .training-text {
          display: flex;
          flex-direction: column;
          width: 50%;
          text-align: left;

          @include media(tablet) {
            width: 100%;
            order: -1;
            margin-bottom: 24px;
          }

          @include media(mobile) {
            width: 100%;
            margin-bottom: 48px;
          }

          .heading {
            color: #822841;
            font-size: 24px;
            margin-bottom: 16px;
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }

          .sub-heading {
            //color: #ffd750;
            margin-bottom: 24px;
            font-family: 'Sharp Grotesk Semibold', sans-serif;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: 0em;
            text-align: left;

            @include media(mobile) {
              font-size: 32px;
              font-weight: 600;
              line-height: 40px;
            }
          }

          .main-text {
            //color: #ffd750;
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }
  }

  .technology {
    //background-color: #822841;
    padding: 0px;

    .technology-container {
      max-width: 1184px;
      margin: 100px auto 85px auto;

      @include media(tablet) {
        margin: 80px 32px 75px 32px;
      }

      @include media(mobile) {
        margin: 48px 16px 48px 16px;
      }

      .top-part {
        display: flex;
        justify-content: space-between;
        margin-bottom: 150px;

        @include media(tablet) {
          flex-direction: column;
          margin-bottom: 100px;
        }

        @include media(mobile) {
          flex-direction: column;
          margin-bottom: 40px;
        }

        .row {
          display: flex;
          flex-direction: column;
          text-align: left;
          width: 50%;

          @include media(tablet) {
            width: 100%;
            margin-bottom: 80px;
          }

          @include media(mobile) {
            width: 100%;
          }

          .header-text {
            color: #822841;
            margin-bottom: 14px;
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }

          .sub-text {
            //color: #ff7350;
            font-family: 'Sharp Grotesk Semibold', sans-serif;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 24px;

            @include media(mobile) {
              font-size: 32px;
              line-height: 40px;
            }
          }

          .text {
            //color: #fff;
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }

      .bottom-part {
        display: flex;
        justify-content: space-between;

        @include media(tablet) {
          flex-direction: column;
        }

        @include media(mobile) {
          flex-direction: column;
        }

        .row {
          display: flex;
          flex-direction: column;
          text-align: left;
          width: 50%;

          @include media(tablet) {
            width: 100%;
            margin-bottom: 80px;
          }

          @include media(mobile) {
            width: 100%;
            margin-bottom: 40px;
          }

          .header-text {
            color: #822841;
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 14px;
          }

          .sub-text {
            //color: #ff7350;
            font-family: 'Sharp Grotesk Semibold', sans-serif;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 24px;

            @include media(mobile) {
              font-size: 32px;
              line-height: 40px;
            }
          }

          .text {
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }
  }

  .altImageText_block {
    padding: 0px;

    .container_wrap {
      margin: 85px 100px 85px;

      @media only screen and (min-width: 950px) and (max-width: 1042px) {
        margin: 100px 48px 75px;
      }

      @include media(tablet) {
        margin: 100px 32px 75px 32px;
      }

      @include media(mobile) {
        margin: 48px 24px 60px 24px;
      }

      .feature_block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 100px;

        @include media(tablet) {
          margin-bottom: 100px;
        }

        @include media(mobile) {
          flex-direction: column;
          margin-bottom: 40px;
        }

        &.topAlign {
          align-items: flex-start;

          @include media(tablet) {
            align-items: center;
          }
        }

        &.bottomAlign {
          align-items: flex-end;
        }

        .row {
          display: flex;
          flex-direction: column;
          text-align: left;
          width: 50%;
          max-width: 750px;
          min-width: 400px;

          @include media(mobile) {
            margin-bottom: 30px;
            width: 100%;
            min-width: 100%;
          }

          .header-text {
            color: #463264;
            margin-bottom: 14px;
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }

          .sub-text {
            font-family: 'Sharp Grotesk Semibold', sans-serif;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 24px;

            @media only screen and (min-width: 950px) and (max-width: 1042px) {
              font-size: 32px;
              line-height: 40px;
            }

            @include media(tablet) {
              font-size: 32px;
              line-height: 40px;
            }

            @include media(mobile) {
              font-size: 32px;
              line-height: 40px;
            }
          }

          .text {
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }

          .header-text,
          .sub-text,
          .text {
            padding: 0px 60px 0px 0px;

            @include media(mobile) {
              padding: 0px;
            }
          }
        }

        .row-sell-page {
          @include media(mobile) {
            padding: 0px 16px;
          }
        }

        .image_wrap {
          img {
            max-width: 100%;
          }
        }

        &.reverse {
          flex-direction: row-reverse;

          @include media(mobile) {
            flex-direction: column;
            margin-bottom: 40px;
          }

          .row {
            padding: 0px 0px 0px 60px;

            @include media(mobile) {
              padding: 0px;
            }
          }
        }

        &.mobi_reverse {
          @include media(mobile) {
            flex-direction: column-reverse;
            margin-bottom: 40px;
          }

          .row {
            @include media(mobile) {
              padding: 0px;
            }
          }

          .image_wrap {
            margin-bottom: 40px;
          }
        }

        &.tab_reverse {
          @media only screen and (min-width: 950px) and (max-width: 1042px) {
            flex-direction: column-reverse;
            margin-bottom: 40px;
          }

          @include media(tablet) {
            flex-direction: column-reverse;
            margin-bottom: 40px;
          }

          .row {
            @media only screen and (min-width: 950px) and (max-width: 1042px) {
              padding: 0px;
              min-width: 100%;
            }

            @include media(tablet) {
              padding: 0px;
              min-width: 100%;
            }
          }

          .image_wrap {
            margin-bottom: 40px;
          }
        }

        &.align_center {
          @media only screen and (min-width: 950px) and (max-width: 1042px) {
            align-items: center;
          }

          @include media(tablet) {
            align-items: center;
          }

          @include media(mobile) {
            align-items: center;
          }

          .header-text,
          .sub-text,
          .text {
            @media only screen and (min-width: 950px) and (max-width: 1042px) {
              text-align: center;
              padding: 0;
            }

            @include media(tablet) {
              text-align: center;
              padding: 0;
            }

            @include media(mobile) {
              text-align: center;
              padding: 0;
            }
          }
        }
      }
    }

    &.fullWidth_block {
      .container_wrap {
        max-width: 100%;
        margin: 85px 0px 85px 100px;

        @media only screen and (min-width: 950px) and (max-width: 1042px) {
          margin: 100px 0px 75px 48px;
        }

        @include media(tablet) {
          margin: 100px 0px 75px 32px;
        }

        @include media(mobile) {
          margin: 75px 24px 75px 24px;
        }

        .image_wrap {
          text-align: right;
          margin-right: -24px;
        }
      }

      &.reverse {
        .container_wrap {
          max-width: 100%;
          margin: 85px 100px 85px 0px;

          @include media(tablet) {
            margin: 100px 32px 75px 0px;
          }

          @include media(mobile) {
            margin: 75px 24px 75px 24px;
          }

          .image_wrap {
            text-align: left;
            margin-left: -24px;
          }
        }
      }
    }

    &.pineGreen_bg {
      background-color: #195a50;

      .container_wrap {
        @include media(mobile) {
          margin: 75px 24px 75px 24px;
        }

        .feature_block {
          margin-bottom: 0px;

          .row {
            .header-text {
              color: #87c864;
            }

            .sub-text,
            .text {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .culture-mission-values {
    background-color: #faf5f0;
    padding: 0px;

    .values-container {
      padding: 175px 0px;

      @include media(tablet) {
        min-height: 2300px;
        padding: 100px 0px;
        padding-left: 32px;
      }

      @include media(mobile) {
        padding: 40px 0px;
        padding-left: 16px;
      }

      //color: #463264;
      .top-part {
        display: flex;
        justify-content: space-between;
        padding-left: 100px;

        @include media(tablet) {
          padding-left: 32px;
        }

        @include media(mobile) {
          flex-direction: column;
          padding-left: 16px;
        }

        .heading-text {
          font-family: 'Poppins', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
        }

        .sub-text {
          font-family: 'Sharp Grotesk Semibold', sans-serif;
          font-size: 48px;
          font-weight: 600;
          line-height: 56px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 24px;

          @include media(mobile) {
            font-size: 32px;
            line-height: 40px;
          }
        }

        .text {
          font-size: 20px;
        }

        .row {
          display: flex;
          flex-direction: column;
          width: 40%;
          text-align: left;

          @include media(tablet) {
            margin-bottom: 24px;
            width: 90%;
          }

          @include media(mobile) {
            margin-bottom: 24px;
            width: 90%;
          }
        }

        @include media(tablet) {
          flex-direction: column;
        }

        @include media(mobile) {
          flex-direction: column;
        }
      }

      .middle-part {
        display: flex;
        justify-content: space-between;
        padding: 100px;

        @include media(tablet) {
          flex-direction: column;
          padding: 50px 32px 100px 32px;
        }

        @include media(mobile) {
          flex-direction: column;
          padding: 50px 16px 100px 16px;
        }

        .row {
          display: flex;
          flex-direction: column;
          text-align: left;

          @include media(tablet) {
            width: 100%;
            order: -1;
            margin-bottom: 40px;
          }

          @include media(mobile) {
            width: 100%;
            order: -1;
          }

          .heading-text {
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }

          .sub-heading {
            font-family: 'Sharp Grotesk Semibold', sans-serif;
            font-size: 48px;
            font-weight: 600;
            font-style: normal;
            line-height: 56px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 40px;

            @include media(mobile) {
              font-size: 32px;
              line-height: 40px;
            }
          }
        }
      }

      .bottom-part {
        text-align: left;
        padding: 0px 100px;

        @include media(tablet) {
          margin: 0px;
          display: flex;
          flex-direction: column;
          padding: 0px 32px;
        }

        @include media(mobile) {
          margin: 0px;
          display: flex;
          flex-direction: column;
          padding: 0px 16px;
        }

        .heading-text {
          display: block;
          font-family: 'Poppins', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 40px;

          @include media(tablet) {
            margin-bottom: 24px;
          }

          @include media(mobile) {
            margin-bottom: 24px;
          }
        }

        .values {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 7rem;
          row-gap: 2rem;

          @include media(tablet) {
            display: flex;
            flex-direction: column;
          }

          @include media(mobile) {
            display: flex;
            flex-direction: column;
          }

          .value {
            font-size: 40px;
            font-weight: 600;
            line-height: 48px;
            display: flex;
            align-items: center;

            @include media(tablet) {
              font-size: 32px;
              line-height: 40px;
            }

            @include media(mobile) {
              font-size: 32px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }

  .reasoning {
    //background-color: #ffffff;
    padding: 48px 128px 64px 128px;

    @media only screen and(max-width: 1200px) {
      padding: 48px 32px 64px 32px;
    }

    @include media(mobile) {
      padding: 48px 16px 64px 16px;
    }

    .reasoning-container {
      max-width: 1184px;
      margin: 0px auto 80px auto;

      @include media(mobile) {
        margin: 0px auto 40px auto;
      }

      h1 {
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;

        @include media(mobile) {
          font-size: 32px;
          font-weight: 600;
          line-height: 40px;
        }
      }

      .reasons {
        .reason {
          padding: 24px;
          margin: 18px 0px;
          border-radius: 32px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .reason-text {
            display: block;
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            width: 50%;

            @include media(tablet) {
              font-size: 15px;
              line-height: 23px;
              text-align: left;
              width: 70%;
            }

            @include media(mobile) {
              width: auto;
              flex: 1;
              font-size: 12px;
              line-height: 18px;
              text-align: center;
            }
          }

          .column-one {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            font-family: 'Sharp Grotesk Semibold', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: center;
            margin: 0px;

            @include media(mobile) {
              width: auto;
              font-size: 16px;
              line-height: 24px;
              order: -1;
            }
          }

          .column-two {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;
            font-family: 'Sharp Grotesk Semibold', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: center;
            margin: 0px;

            @include media(mobile) {
              width: auto;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
            }
          }
        }

        .reason:nth-of-type(n) {
          background-color: #ffffff;
        }

        .reason:nth-of-type(2n) {
          background-color: #e3deed;
        }
      }
    }

    .get-started-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;

      @include media(mobile) {
        flex-direction: column;
      }

      .apply-button {
        color: #ffffff;
        margin-right: 30px;
        width: 260px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        @include media(mobile) {
          margin: 15px auto;
        }
      }

      .schedule-button {
        margin-right: 30px;
        width: 260px;
        background-color: #fff;
        color: #ff7350;
        border: 1px solid #ff7350;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        @include media(mobile) {
          margin: 5px 0px;
        }
      }
    }

    .recruiting-markets-container {
      display: flex;
      flex-direction: column;
      margin: 50px 0px;
      font-family: $mainFont;

      .headingText {
        font-size: 32px;
        font-weight: 600;
        margin: 20px 0px;
      }

      .location-container {
        display: flex;
        flex-direction: column;
        margin: 20px 0px;

        .stateName {
          font-size: 24px;
          font-weight: 600;
        }

        .locationName {
          font-size: 20px;
          font-weight: 400;
          font-family: 'Poppins', sans-serif;
        }
      }
    }
  }

  .reviews-header-container {
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
    margin-bottom: 50px;
    max-width: 1184px;

    @include media(tablet) {
      padding: 0px 32px;
    }

    @include media(mobile) {
      padding: 0px 16px;
    }

    .navigationButtonContainer {
      margin: 0px;

      @include media(tablet) {
        display: none;
      }

      @include media(mobile) {
        display: none;
      }
    }

    h2 {
      color: #000000;
      margin: 0px;
      font-family: 'Sharp Grotesk Semibold', sans-serif;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
      letter-spacing: 0em;
      text-align: left;

      @include media(tablet) {
        font-size: 48px;
        line-height: 56px;
      }

      @include media(mobile) {
        font-size: 26px;
        line-height: 40px;
      }
    }

    @media only screen and (max-width: 1240px) and (min-width: 950px) {
      h2 {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  @media only screen and (max-width: 1240px) and (min-width: 950px) {
    .reviews-header-container {
      margin: 0px 32px;
      margin-bottom: 24px;
    }
  }

  .testimonials {
    .hero-button-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 40px auto;

      @include media(mobile) {
        flex-direction: column;
        align-items: center;
      }

      .apply-button {
        color: #ffffff;
        width: 260px;
        margin-right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        @include media(mobile) {
          margin: 15px auto;
        }
      }

      .schedule-button {
        width: 260px;
        background-color: #fff;
        color: #ff7350;
        border: 1px solid #ff7350;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        @include media(mobile) {
          margin: 5px 0px;
        }
      }
    }
  }

  .tools {
    padding: 100px 0 20px 0;

    @include media(mobile) {
      padding: 0 30px 20px;
    }

    .tools-content {
      margin: 0 auto;
      width: 960px;

      @include media(tablet) {
        width: 760px;
      }

      @include media(mobile) {
        width: 100%;
      }
    }
  }

  @include media(mobile) {
    .slider-frame {
      width: 360px;

      .slider-slide {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.house-icon {
  position: relative;
  margin: 0 auto;
  width: 581px;
  height: 250px;

  @include media(tablet) {
    width: 359px;
    height: 155px;
  }

  @include media(mobile) {
    width: 359px;
    height: 155px;
  }
}

@-webkit-keyframes AnimateBackground {
  0% {
    background-position: 0 84%;
  }

  50% {
    background-position: 100% 16%;
  }

  100% {
    background-position: 0 84%;
  }
}

@-moz-keyframes AnimateBackground {
  0% {
    background-position: 0 84%;
  }

  50% {
    background-position: 100% 16%;
  }

  100% {
    background-position: 0 84%;
  }
}

@keyframes AnimateBackground {
  0% {
    background-position: 0 84%;
  }

  50% {
    background-position: 100% 16%;
  }

  100% {
    background-position: 0 84%;
  }
}

//About Page.
.about-us {
  background: #faf5f0;

  .about-hero-banner {
    width: 100%;
    padding: 0;
    margin: 0 0 32px 0;

    @include media(mobile) {
      margin: 0;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .altImageText_block {
    padding: 0px;

    .container_wrap {
      margin: 85px 100px 85px;

      @media only screen and (min-width: 950px) and (max-width: 1042px) {
        margin: 48px 32px;
      }

      @include media(tablet) {
        margin: 48px 32px;
      }

      @include media(mobile) {
        margin: 48px 24px 60px 24px;
      }
    }
  }

  .section_head {
    .container_wrap {
      margin: 85px 100px 85px;

      @media only screen and (min-width: 950px) and (max-width: 1042px) {
        margin: 100px 48px 75px;
      }

      @include media(tablet) {
        margin: 100px 32px 75px 32px;
      }

      @include media(mobile) {
        margin: 32px 24px 32px 24px;
      }
    }

    .section-heading {
      font-family: 'Sharp Grotesk Semibold', sans-serif;
      font-weight: 600;
      font-size: 48px;
      line-height: 64px;
      text-align: center;
      color: #2d2332;

      @include media(mobile) {
        font-size: 40px;
        line-height: 64px;
      }
    }

    .sub-heading {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #463264;
    }
  }

  .ourTeam_section {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0px;

    .founders_block {
      background-color: #195a50;

      .titleHead {
        font-family: 'Sharp Grotesk Semibold';
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        color: #ffffff;
        margin: 64px 0;

        @include media(mobile) {
          margin: 64px 32px 32px;
        }
      }

      .founder_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 32px 100px 85px;

        @media only screen and (min-width: 950px) and (max-width: 1042px) {
          margin: 32px 48px 75px;
        }

        @include media(tablet) {
          margin: 32px 32px 75px 32px;
        }

        @include media(mobile) {
          flex-direction: column;
          margin: 32px 24px 60px 24px;
        }
      }

      .founder_card {
        display: flex;
        flex-direction: column;
        width: 260px;
        margin: 0 40px;

        @media only screen and (min-width: 950px) and (max-width: 1042px) {
          margin: 0 40px;
        }

        @include media(tablet) {
          margin: 0 40px;
        }

        @include media(mobile) {
          margin: 0 0 40px 0;
        }

        img {
          width: 100%;
          margin-bottom: 32px;
        }

        .name {
          font-family: 'Sharp Grotesk Semibold';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #ffffff;
        }

        .role {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          color: #ffffff;
          text-transform: uppercase;
        }
      }
    }

    .container_wrap {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 100px 100px 85px;

      @media only screen and (min-width: 950px) and (max-width: 1042px) {
        margin: 100px 48px 75px;
      }

      @include media(tablet) {
        margin: 100px 32px 75px 32px;
      }

      @include media(mobile) {
        margin: 48px 24px 60px 24px;
      }

      .heading {
        font-family: 'Sharp Grotesk Semibold';
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        text-align: center;
        color: #2d2332;
        margin-bottom: 16px;
      }

      .mainText {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #2d2332;
        max-width: 816px;
      }
    }

    .btn_wrap {
      margin: 32px 0;
      text-align: center;
    }
  }

  .btnHiring {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 48px;
    width: 265px;
    height: 76px;
    background: #463264;
    border-radius: 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    text-decoration: none;

    &:hover {
      color: #463264;
      background: #ffffff;
      border: 2px solid #463264;
    }
  }

  .map_container {
    padding: 0 64px;

    @media only screen and (min-width: 950px) and (max-width: 1042px) {
      padding: 0 64px;
    }

    @include media(tablet) {
      padding: 0 32px;
    }

    @include media(mobile) {
      padding: 0 16px;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .press-container-wrap {
    margin: 80px 0;
    justify-content: center;

    @include media(tablet) {
      margin: 64px 0;
    }

    @include media(mobile) {
      margin: 40px 0;
    }

    .section_title {
      font-family: 'Sharp Grotesk Semibold';
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      color: #2d2332;
      margin: 56px auto;
    }
  }

  .press-container-about-page {
    margin: 80px 0;
    justify-content: center;
  }

  .blog_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    @media only screen and (min-width: 950px) and (max-width: 1042px) {
      padding: 0 64px;
    }

    @include media(tablet) {
      padding: 0 64px;
    }

    @include media(mobile) {
      padding: 0 32px;
    }

    .blog_section_title {
      font-family: 'Sharp Grotesk Semibold';
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      color: #2d2332;
      margin: 56px auto;
    }

    .blog_cards_wrap {
      display: flex;
      flex-direction: row;

      @include media(tablet) {
        flex-direction: column;
        width: 100%;
      }

      @include media(mobile) {
        flex-direction: column;
      }

      .blog_card {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 412px;

        @media only screen and (min-width: 950px) and (max-width: 1042px) {
          width: 100%;
        }

        @include media(tablet) {
          width: 100%;
          margin: 24px 0;
        }

        @include media(mobile) {
          width: 100%;
          margin: 24px 0;
        }

        img {
          width: 100%;
          object-fit: cover;
          margin-bottom: 24px;
          border-radius: 24px;

          @include media(tablet) {
            height: 215px;
          }
        }

        .blog_title {
          font-family: 'Sharp Grotesk Semibold';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: #2d2332;
        }

        &:nth-child(2) {
          margin: 0 24px;

          @include media(tablet) {
            margin: 24px 0;
          }

          @include media(mobile) {
            margin: 24px 0;
          }
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  ul.checkCircle_listing {
    list-style: none;
    padding: 0px;
    margin: 24px 0px 0px;

    li {
      font-family: Sharp Grotesk Semibold;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      list-style: none;
      display: flex;
      align-items: center;
      margin: 10px 0px;
    }

    svg {
      margin-right: 5px;
    }
  }

  .what-is-a-torii {
    height: 800px;
    width: 785px;
    margin-bottom: 100px;

    @include media(mobile) {
      width: 390px;
      padding-top: 0px;
      height: 1000px;
      margin-bottom: 50px;
    }

    h1 {
      @include page-header();
      text-align: center;
      color: black;
      font-size: 36px !important;

      @include media(mobile) {
        text-align: center;
        margin-bottom: 40px;
      }
    }

    .torii-images {
      height: 299.19px;
      width: 521px;
      margin: 0 auto;

      @include media(tablet) {
        margin-bottom: 10px;
      }

      @include media(mobile) {
        margin-bottom: 10px;
        width: 380px;
      }

      .torii-img-1 {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 299.19px;
        width: 521px;
        background-image: url('/static/img/about/torii_big.jpg');
        margin-bottom: 50px;

        @include media(tablet) {
          margin-bottom: 10px;
        }

        @include media(mobile) {
          margin-bottom: 10px;
          width: 380px;
          background-image: url('/static/img/about/torii_small.jpg');
        }
      }
    }

    .torii-inner {
      max-width: 800px;
      margin: 0 auto;

      @include media(tablet) {
        padding: 0 5%;
      }

      @include media(mobile) {
        padding: 0 10%;
        margin: 40px auto 0;
      }

      p {
        color: black;
        text-align: left;
        line-height: 30px;
        font-size: 14px;
        font-weight: normal;
        text-align: justify;
      }
    }
  }
}

.buying-guide {
  .explainer-video-container {
    margin-top: 50px;

    @include media(mobile) {
      padding: 0;
    }

    .explainer-video {
      height: 315px;
      width: 560px;

      @include media(mobile) {
        height: 206px;
        width: 365px;
      }
    }
  }

  .buying-guide-inner {
    max-width: 850px;
    margin: 0 auto;
  }

  p {
    color: $text-color-light;
    font-size: 22px;
    line-height: 35px;
    margin: 0;
    text-align: left;

    @include media(mobile) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .hero-banner {
    padding: 0;
    height: 175px;
    background: linear-gradient(45deg, #4a90e2 0%, #5bd3f7 100%);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include media(tablet) {
      height: 175px;
    }

    @include media(mobile) {
      height: 150px;

      &.buying-guide-bg {
        height: 150px;
      }
    }

    .hero-banner-inner {
      .how-it-works {
        color: #ffffff;
        font-size: 36px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 44px;
        text-align: center;
        margin: 0;
        text-shadow: none;
      }

      .helpful-guide {
        color: #ffffff;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 30px;
        text-align: center;
        margin-bottom: 0;
        text-shadow: none;

        @include media(mobile) {
          font-size: 18px;
          font-weight: normal;
          line-height: 22px;
        }
      }
    }

    &.buying-guide-bg {
      background-position: bottom;
      background-image: url('/static/img/buying-guide/header_gradient_desktop.png');

      @include media(tablet) {
        background-position: bottom;
        background-image: url('/static/img/buying-guide/header_gradient_tablet.png');
      }

      @include media(mobile) {
        background-position: bottom;
        background-image: url('/static/img/buying-guide/header_gradient_mobile.png');
      }
    }
  }

  .steps-box {
    margin: 80px auto 0;
    position: relative;
    width: 850px;

    @include media(tablet) {
      width: 694px;
    }

    @include media(mobile) {
      width: 337px;
      margin: 50px auto 0;
    }
  }

  .rectangle {
    height: 98%;
    width: 3px;
    border-radius: 3.5px;
    background-color: #f0f0f0;
    position: absolute;
    margin-left: 16px;

    @include media(tablet) {
      height: 1090px;
    }

    @include media(mobile) {
      height: 1700px;
      margin-left: 15px;
    }
  }

  .step-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 35px;
    width: 850px;

    @include media(tablet) {
      width: 694px;
    }

    @include media(mobile) {
      width: 337px;
      flex-direction: column;
    }
  }

  .first-column {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
    z-index: 1;

    @include media(mobile) {
      justify-content: normal;
    }
  }

  .step-index {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: $purple-primary;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .step-index-text {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin: 0;
    margin-top: -1px;
  }

  .step-title {
    color: #555555;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    margin: 0;
    margin-left: 35px;

    @include media(mobile) {
      margin-left: 25px;
    }
  }

  .step-content-container {
    width: 500px;
    height: 100%;
    display: flex;
    justify-content: flex-start;

    @include media(tablet) {
      width: 407px;
    }

    @include media(mobile) {
      width: 280px;
      margin-left: 70px;
    }
  }

  .step-content {
    color: #555555;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
    text-align: left;

    a {
      text-decoration: none;
    }
  }

  .buying-guide-content {
    padding: 85px 0;

    @include media(desktop) {
      padding: 70px 0;
    }

    @include media(tablet) {
      padding: 40px 0;
    }

    @include media(mobile) {
      padding: 40px 0;
    }
  }

  .guide-section {
    text-align: left;
    max-width: 910px;
    margin: 0 auto 30px;

    @include media(tablet) {
      max-width: 700px;
    }

    @include media(mobile) {
      max-width: 90%;
    }

    .guide-section-title {
      .guide-section-icon-container {
        margin-right: 20px;

        @include media(mobile) {
          margin: 0 auto;
        }
      }

      h2 {
        font-size: 36px;
        line-height: 36px;
        color: $text-color-light;
        margin: 15px 0;

        @include media(mobile) {
          margin: 15px auto;
          width: 100%;
          text-align: center;
        }
      }
    }

    .guide-section-content {
      @include media(mobile) {
        margin: 0 auto;
        width: 300px;
      }

      ul {
        list-style: disc;
        padding-left: 120px;
        margin-top: 0;

        @include media(desktop) {
          padding-left: 100px;
        }

        @include media(tablet) {
          padding-left: 40px;
        }

        @include media(mobile) {
          padding-left: 0;
        }

        li {
          font-size: 22px;
          line-height: 35px;
          color: $text-color-light;

          @include media(mobile) {
            font-size: 18px;
            line-height: 28px;
          }

          p {
            padding-left: 20px;
          }
        }

        .sub-list {
          padding-left: 50px;
          list-style: circle;

          @include media(tablet) {
            padding-left: 20px;
          }

          @include media(mobile) {
            padding-left: 10px;
          }
        }
      }
    }
  }

  .faq-container {
    margin-top: 15px;
    margin-bottom: 70px;

    @include media(mobile) {
      padding-left: 5%;
      padding-right: 5%;
      margin-bottom: 40px;
    }
  }
}

//Favorites Page
.favorites-container {
  .dashboard-section {
    .dashboard-section-inner {
      @include dashed-border();
      padding: 50px 0;

      &.empty {
        padding: 85px 0;
      }
    }
  }

  .dashboard-feed {
    .btn-container {
      margin-top: 15px;
    }
  }

  .to-do-container {
    margin-bottom: 30px;

    @include media(mobile) {
      margin-bottom: 15px;
    }

    .to-do-card-avatar {
      @include media(mobile) {
        margin-top: 12px;
      }
    }

    svg {
      path {
        fill-opacity: 1 !important;
      }
    }
  }
}

//Listings Page
.listing-container {
  overflow: hidden;

  .listing {
    .listing-inner {
      padding: 0;
    }

    .listing-pipe {
      padding: 0 3px;
    }

    .section-title {
      margin-bottom: 10px;

      @include media(mobile) {
        text-align: center;
        margin: 0 auto;
        font-size: 22px;
      }
    }

    .tool-link {
      color: $text-color-light;
    }

    .listing-top {
      max-width: 1050px;
      margin: 35px auto;

      @include media(desktop) {
        max-width: 90%;
      }

      @include media(tablet) {
        max-width: 90%;
      }

      @include media(mobile) {
        max-width: auto;
        margin: 0 auto 15px;
      }

      .listing-address {
        padding-bottom: 15px;
        border-bottom: 1px solid $grey-accent;
        display: table;

        @include media(mobile) {
          text-align: center;
          margin: 10px auto;
        }

        .toggle-favorite {
          margin: 0;

          @include media(mobile) {
            margin: 0;
          }
        }
      }

      .offer-btn {
        margin: auto;
      }
    }

    .slider-outer-container {
      width: 100%;
      height: 300px;
      margin: 0 auto;
      background-color: rgba(0, 0, 0, 0.75);

      @include media(tablet) {
        max-width: 600px;
      }

      @include media(mobile) {
        max-width: 350px;
      }

      &.fullscreen {
        height: auto;
        display: flex;
        align-items: center;

        .container {
          margin-bottom: 0;
        }

        @include media(gt-tablet) {
          position: fixed;
          top: 0;
          height: 100%;
          z-index: 10000;

          .listing-slider-img-container {
            height: auto;
            display: flex;

            &:focus {
              outline: none !important;
            }

            .listing-slider-img {
              margin: 0 auto;
              width: auto;
              height: 100%;
            }
          }
        }

        .slider-list {
          align-items: center;
          display: flex !important;
        }

        .slider-slide {
          top: auto !important;
        }
      }

      .img-index-container {
        display: flex;
        width: 100%;
        margin-top: 15px;
        justify-content: center;
        align-items: center;
        position: absolute;

        .img-index {
          height: 35px;
          width: 75px;
          border-radius: 2px;
          background-color: rgba(0, 0, 0, 0.65);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 18px;
        }
      }

      .close-button-container {
        position: absolute;
        z-index: 1001;
        right: 40px;
        top: 30px;
      }

      .listing-slider-img-container {
        height: 300px;
        overflow: hidden;

        .listing-slider-img {
          object-fit: cover;
          width: 100%;
          height: 100%;

          @include media(gt-tablet) {
            cursor: pointer;
          }
        }
      }
    }

    .listing-main-content {
      max-width: 1050px;
      margin: 50px auto 0;

      @include media(desktop) {
        max-width: 90%;
      }

      @include media(tablet) {
        max-width: 90%;
      }

      .listing-details {
        max-width: 680px;

        @include media(mobile) {
          max-width: 90%;
          margin: 0 auto;
        }

        .listing-size {
          margin-bottom: 10px;

          h3 {
            font-weight: bold;
          }

          .icon-building {
            display: none;
            width: 38px;
            height: 38px;
            background: $text-color-light;
          }
        }
      }

      .listing-actions {
        @include media(mobile) {
          margin: 0 auto;
        }

        .comps-btn {
          margin-bottom: 20px;
        }

        .showing-request-container {
          margin-bottom: 20px;
        }

        .listing-open-houses {
          border: 2px solid #555555;
          border-radius: 2px;
          padding: 10px 15px;

          ul {
            list-style: inherit;
            margin-bottom: 8px;
          }
        }
      }

      .listing-timing {
        max-width: 100%;
        margin-top: 20px;
      }

      .listing-description {
        margin-bottom: 10px;
      }

      .expand-toggle {
        cursor: pointer;
        font-size: 14px;
        text-transform: initial;
        padding: 0;
      }

      .listing-detail-list {
        list-style: inherit;
        padding-left: 20px;

        .listing-detail-more {
          opacity: 0;
          max-height: 0;
          -webkit-transition: 0.25s ease;
          transition: 0.25s ease;

          &.expanded {
            opacity: 1;
            max-height: 999em;
          }
        }

        li {
          color: $text-color-light;
          font-size: 18px;
          font-weight: 300;
          line-height: 26px;

          @include media(mobile) {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }

      .listing-detail-bottom {
        font-size: 14px;
      }
    }

    .listing-map-container {
      margin: 0 auto;

      .map-inner-container {
        height: 590px;
        width: 590px;
        margin: 0 auto;

        @include media(desktop-xl) {
          width: 1050px;
        }

        @include media(mobile) {
          height: 300px;
          width: 300px;
        }

        .marker-overlay-content {
          width: 225px;
          height: 70px;
          text-decoration: none !important;
          padding: 0;
          cursor: pointer;
          color: rgba(0, 0, 0, 0.87);
          border: 1px solid #cccccc;
          background: white;

          .overlay-container {
            align-items: center;
            display: flex;
            padding: 3px;
            background: white;
            height: 100%;
            width: 100%;

            &:hover {
              background-color: rgba(0, 0, 0, 0.1);
            }

            .image-container {
              height: 68px;
              margin-right: 10px;
              padding-bottom: 3px;
              padding-top: 3px;
              width: 68px;

              .property-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .info-container {
              .price-tag {
                font-size: 14px;
                font-weight: bold;
                line-height: 17px;
                margin: 0 0 1px 0;
                overflow: hidden;
                text-align: left;
                text-decoration: none !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 123px;
              }

              .address-tag {
                font-size: 12px;
                line-height: 15px;
                margin: 0 0 5px 0;
                overflow: hidden;
                text-align: left;
                text-decoration: none !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 123px;
              }

              .details-tag {
                font-size: 12px;
                line-height: 15px;
                margin: 0;
                overflow: hidden;
                text-align: left;
                text-decoration: none !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 123px;
              }
            }
          }
        }
      }
    }

    .listing-data-dive {

      // An inherited style is cutting off part of the chart axis label. This fixes it.
      svg:not(:root) {
        overflow: visible;
      }

      .listing-data-dive-trend {
        margin: 20px 0 35px;

        .listing-trend-arrow {
          font-size: 45px;
          margin-right: 15px;
          height: 48px;
          width: 48px;
          background-position: center center;
          background-repeat: no-repeat;
          background-image: url('/static/img/up_arrow.svg');
          color: $purple-primary;

          &.down-arrow {
            background-image: url('/static/img/down_arrow.svg');
            color: $pink-primary;
          }
        }

        .listing-trend-text {
          max-width: 210px;
        }
      }
    }

    .listing-preview-section {
      margin: 0 auto 50px;

      .show-more-listings-btn {
        margin: 20px auto 0;
      }
    }

    .listing-comps {
      position: relative;

      .compDetails {
        margin: 20px 0 5px;
      }
    }
  }
}

//Random Page
.random-home-container {
  .header-buttons {
    position: absolute;
    width: 100%;
    z-index: 3;
    padding-top: 10px;
    top: 0;

    .price-button {
      margin-right: 10px;
    }
  }

  .tool-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #eb6565;
    background: radial-gradient(circle, #eb6565 0%, #d84e4e 100%);

    &.left-dot {
      margin-right: 15px;
    }

    &.right-dot {
      margin-left: 15px;
    }
  }

  .social-container {
    margin-bottom: 20px;

    .social-icon-button {
      display: inline-block;
      margin: 0 10px;

      .social-icon-button-2 {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0px;
        margin: 0px;
      }

      .social-icon {
        height: 25px;
        width: 25px;
        text-indent: -9999999px;

        &.fb {
          background: url('/static/img/social_icon_fb.png') no-repeat 0 0;
          background-size: 100%;
        }

        &.tw {
          background: url('/static/img/social_icon_tw.png') no-repeat 0 0;
          width: 31px;
          background-size: 100%;
        }

        &.li {
          background: url('/static/img/social_icon_li.png') no-repeat 0 0;
          background-size: 100%;
        }
      }
    }
  }
}

.random-home-dialog-main-title {
  font-size: 20px;
  padding-bottom: 3px !important;
  font-weight: bold;
}

.random-home-dialog-secondary-title {
  margin: 0 0 15px;
  font-size: 18px;
}

//Reset Page
.form-page {
  .envelope-icon {
    height: 18px;
    width: 22.5px;
    object-fit: cover;
    margin-right: 15px;
    position: absolute;
    left: -30px;
    top: 8px;

    @include media(mobile) {
      position: relative;
      left: 0px;
      top: 0px;
    }
  }
}

//Search Page
.no-results-container {
  text-align: center;
  padding-top: 30px;
}

.feed {
  .search-result-container {
    .listings-container {
      .feed-top {
        max-width: 1200px;
        margin: 0 auto 30px;

        @include media(mobile) {
          padding-top: 25px;
        }

        .feed-title {
          text-align: left;
          padding-left: 20px;
          color: #555555;

          @include media(mobile) {
            text-align: center;
            margin: 0 auto;
            padding-left: 0;
          }
        }

        .feed-details {
          text-align: left;

          .listing-top-item {
            margin: 5px 0;

            h1 {
              color: #555555;
              font-size: 40px;
              font-weight: bold;
              line-height: 57px;
              margin: 0;
            }

            h3 {
              color: #555555;
              font-size: 24px;
              line-height: 29px;
              font-weight: 300;
              margin: 0;

              @include media(mobile) {
                font-size: 18px;
                line-height: 22px;
              }
            }
          }
        }

        .listing-map-container {
          .map-inner-container {
            height: 400px;
            width: 800px;
            margin: 0 0 0 auto;

            @include media(desktop) {
              width: 700px;
            }

            @include media(tablet) {
              width: 650px;
            }

            @include media(mobile) {
              height: 300px;
              width: 300px;
            }
          }
        }
      }

      .load-more-button {
        left: 0;
        right: 0;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }

  .carousel-listing-container {
    .slider-list {
      margin: 0px -26px !important;

      :focus {
        outline: none !important;
      }
    }

    .slider-slide {
      :focus {
        outline: none !important;
      }
    }
  }

  .swiper-filters {
    .average-container {
      display: flex;
      margin-left: 25px;
    }
  }
}

//Tools Page
.tool-container {
  h1 {
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 0;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tool-content {
    .tool-parent {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .get-started-link {
    border: 1px solid $purple-primary;
    text-decoration: none;
    max-width: 380px;
    margin: 60px auto;
    border-radius: 2px;

    h2 {
      color: $purple-primary;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }

    p {
      color: $purple-primary;
      font-size: 14px;
      line-height: 18px;
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }

  .closing-costs {
    .closing-costs-form {
      .closing-costs-form-prompt {
        transition: all 0.5s ease-out;
        max-height: auto;

        &.hidden {
          max-height: 0;
          opacity: 0;
        }

        .closing-cost-form-icon {
          height: 75px;
          margin: 20px auto;

          &.price {
            width: 75px;
            background: url('/static/img/tools/price_icon.svg') no-repeat 0 0;
          }

          &.downpayment {
            width: 85px;
            background: url('/static/img/tools/downpayment_icon.svg') no-repeat 0 0;
          }
        }
      }
    }
  }
}

.tools-nav {
  .tools-nav-link {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    outline: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .tools-nav-label {
    font-size: 14px !important;
    letter-spacing: 1px;
    color: white;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    line-height: 20px !important;
    max-width: 100px;
    margin: 5px auto 15px !important;
    height: 40px;

    &.active {
      color: #50e3c2;
    }

    &.dark {
      color: rgba(0, 0, 0, 0.87);
      font-weight: bold !important;
    }
  }

  .button-label {
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    max-width: 100px;
    text-align: center;
    margin: 0 auto;
    margin-top: 0;
  }
}
