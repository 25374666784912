@import '../components/flexboxgrid';
@import '../abstracts/mixins';
@import '../abstracts/variables';

.mobile-only {
  @include media(tablet) {
    display: none !important;
  }
  @include media(desktop) {
    display: none !important;
  }
  @include media(desktop-xl) {
    display: none !important;
  }
}

.tablet-only {
  @include media(mobile) {
    display: none !important;
  }
  @include media(desktop) {
    display: none !important;
  }
  @include media(desktop-xl) {
    display: none !important;
  }
}

.no-desktop-xl {
  @include media(desktop-xl) {
    display: none !important;
  }
}

.desktop-xl-only {
  @include media(mobile) {
    display: none !important;
  }
  @include media(tablet) {
    display: none !important;
  }
  @include media(desktop) {
    display: none !important;
  }
}

.no-tablet {
  @include media(tablet) {
    display: none !important;
  }
}

.no-mobile {
  @include media(mobile) {
    display: none !important;
  }
}

.no-desktop-small {
  @include media(desktop-small) {
    display: none !important;
  }
}

.no-desktop {
  @include media(desktop) {
    display: none !important;
  }
  @include media(desktop-xl) {
    display: none !important;
  }
}

.top-notification {
  background-color: $purple-primary;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-around;
  letter-spacing: 0.01071em;
  line-height: 22px;
  padding: 10px 25px;
  text-align: left;
  width: 100%;
  @include media(tablet) {
    font-size: 14px;
    line-height: 18px;
    padding: 19px 18px;
  }
  @include media(mobile) {
    font-size: 13px;
    line-height: 18px;
    padding: 15px;
  }
  span {
    @include media(tablet) {
      width: 91%;
    }
    @include media(mobile) {
      width: 100%;
    }
  }
  .close-button {
    color: #ffffff;
    @include media(mobile) {
      display: flex;
      justify-content: flex-end;
      padding: 5px;
      width: 100%;
    }
  }
  .bolder {
    font-weight: bold;
  }
  &.hidden {
    display: none;
  }
  a {
    color: white;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    &:visited {
      color: white;
    }
  }
  .spacer {
    height: 10px;
    width: 100%;
  }
}

.bottom-notification {
  position: fixed;
  bottom: 0;
  color: #fff;
  font-weight: 500;
  background-color: $purple-primary;
  display: flex;
  padding: 20px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01071em;
  width: 100%;
  justify-content: space-around;
  z-index: 100;
  &.hidden {
    display: none;
  }
  a {
    color: white;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    &:visited {
      color: white;
    }
  }
  .spacer {
    height: 10px;
    width: 100%;
  }
}

.page-header {
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 80px;
  line-height: 96px;
  @include media(tablet) {
    font-size: 48px;
    line-height: 57px;
  }
  @include media(mobile) {
    font-size: 30px;
    line-height: 36px;
  }
}

.page-subheader {
  font-weight: 500;
  color: white;
  font-size: 36px;
  line-height: 44px;
  @include media(tablet) {
    font-size: 24px;
    line-height: 29px;
  }
  @include media(mobile) {
    font-size: 20px;
    line-height: 24px;
  }
}

.dashed-border {
  background-image: repeating-linear-gradient(
      to right,
      $grey-accent 0%,
      $grey-accent 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to right,
      $grey-accent 0%,
      $grey-accent 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      $grey-accent 0%,
      $grey-accent 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      $grey-accent 0%,
      $grey-accent 50%,
      transparent 50%,
      transparent 100%
    );
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 20px 3px, 20px 3px, 3px 20px, 3px 20px;
}
